import axios from "axios";
import * as types from "./types";
import * as FileSaver from "file-saver";
import { actions } from "react-table";

export const userAction = async (accessToken, dispatch, { token, skip }) => {
  // return function (dispatch) {
  await axios
    // .get(types.API_URL + `/getAllMasterData?skip=${skip}`)
    .get(types.API_URL + `/getAllMasterData`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
    .then(function (response) {
      dispatch({
        type: types.USERLIST_SUCCESS_ACTION,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatch({
        type: types.USERLIST_FAILURE_ACTION,
        payload: error,
      });
    });
  // };
};

export const fetchQuestions = async (accessToken, dispatch, { masterDataId }, { onSuccess = () => { }, onFailure = () => {} }) => {
  // return function (dispatch) {
  await axios
    .get(types.API_URL + `/getAudits?masterDataId=${masterDataId}`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
    .then(function (response) {
      dispatch({
        type: types.FETCH_QUESTIONS_SUCCESS_ACTION,
        payload: response.data,
      });
      onSuccess()
    })
    .catch(function (error) {
      dispatch({
        type: types.FETCH_QUESTIONS_FAILURE_ACTION,
        payload: error,
      });
      onFailure()
    });
  // };
};

export const submitAudits = async (accessToken, dispatch, audits, auditsType, masterDataId) => {
  // return function (dispatch) {
  await axios
    .post(types.API_URL + `/updateStatusCompleted`, {
      audits: audits,
      auditsType: auditsType,
      masterDataId: masterDataId,
    },
      { headers: { 'Authorization': `Bearer ${accessToken}` } }
    )
    .then(function (response) {
      console.log('response', response);
      dispatch({
        type: types.SUBMIT_AUDITS_SUCCESS_ACTION,
        payload: response.data,
      });
    })
    .catch(function (error) {
      console.log('error', error);
      dispatch({
        type: types.SUBMIT_AUDITS_FAILURE_ACTION,
        payload: error,
      });
    });
  // };
};
export const saveAuditRemarks = async (accessToken, dispatch, remarks = "", masterDataId = "") => {
  // return function (dispatch) {
  await axios
    .post(types.API_URL + `/saveRemarks`, {
      remarks: remarks,
      id: masterDataId,
    },
      { headers: { 'Authorization': `Bearer ${accessToken}` } }
    )
    .then(function (response) {
      dispatch({
        type: types.SUBMIT_AUDITS_SUCCESS_ACTION,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatch({
        type: types.SUBMIT_AUDITS_FAILURE_ACTION,
        payload: error,
      });
    });
  // };
};
export const userdata = (accessToken, dispatch, email) => {
  // return function (dispatch) {
  dispatch({
    type: types.SET_EMAIL,
    payload: email,
  });
  // };
};

export const Assignaudits = async (accessToken, dispatch, masterId, userId, { onSuccess, onFailure }) => {
  // return function (dispatch) {
  await axios
    .post(types.API_URL + `/manualAssign`, {
      masterId: masterId,
      userId: userId,
    },
      { headers: { 'Authorization': `Bearer ${accessToken}` } }
    )
    .then(function (response) {
      if (response.data.success == true) {
        dispatch({
          type: types.ASSIGN_AUDITS_SUCCESS_ACTION,
          payload: response.data,
        });
      }
      onSuccess(response);
    })
    .catch(function (error) {
      // dispatch({
      //   type: types.ASSIGN_AUDITS_FAILURE_ACTION,
      //   payload: error,
      // });
      onFailure();
    });
  // };
};

export const filterAudits = async (accessToken, dispatch, filters) => {
  // return function (dispatch) {
  await axios
    .post(types.API_URL + "/filter", filters, { headers: { 'Authorization': `Bearer ${accessToken}` } })
    .then(function (response) {
      dispatch({
        type: types.FILTER_SUCCESS_ACTION,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatch({
        type: types.FILTER_FAILURE_ACTION,
        payload: error,
      });
    });
  // };
};

export const Reportdownload = async (
  accessToken,
  dispatch,
  auditId,
  matrixRefNo,
  checkType,
  auditsType,
  type,
  distance_color,
  { onSuccess, onFailure }
) => {
  // return function (dispatch) {
  await axios
    .post(
      types.API_URL + `/generateReport`,
      { auditId: auditId, type: type },
      {
        responseType: "blob",
        headers: { 'Authorization': `Bearer ${accessToken}` }
      }
    )
    .then(function (response) {
      var text = response.headers['content-disposition'] + ""
      var file_name = text.slice(text.indexOf("=") + 1)
      console.log('file_name', file_name, text, response)
      if (auditsType == 'Residence') {
        FileSaver.saveAs(
          response.data
          // , file_name
        );
        // `${matrixRefNo}-${checkType}-${distance_color}.pdf`
        // alert("Report Send to Your EmailId")
      }
      else {
        console.log('`${matrixRefNo}-${checkType}-${distance_color}.docx`', `${matrixRefNo}-${checkType}-${distance_color}.docx`)
        FileSaver.saveAs(
          response.data
          // , `${matrixRefNo}-${checkType}-${distance_color}.docx`
        );
      }

      dispatch({
        type: types.REPORT_DOWNLOAD_SUCCESS_ACTION,
        payload: response.data,
      });
      onSuccess();
    })
    .catch(function (error) {
      dispatch({
        type: types.REPORT_DOWNLOAD_FAILURE_ACTION,
        payload: error,
      });
      onFailure();
    });
  // };
};

export const ConsolidatedReportdownload = async (accessToken, dispatch, fromDate, toDate, status, teamLeaderId, teamMemberId, fieldExecutiveId, { onSuccess, onFailure }) => {
  // return function (dispatch) {
  await axios
    .get(
      types.API_URL +
      `/generateConsolidatedReport?fromDate=${fromDate}&toDate=${toDate}&status=${status}&teamLeaderId=${teamLeaderId}&teamMemberId=${teamMemberId}&fieldExecutiveId=${fieldExecutiveId}`,
      {
        responseType: "blob",
        headers: { 'Authorization': `Bearer ${accessToken}` }
      }
    )
    .then(function (response) {
      FileSaver.saveAs(response.data, `auditReport.xlsx`);
      dispatch({
        type: types.CONSOLIDATED_REPORT_DOWNLOAD_SUCCESS_ACTION,
        payload: response.data,
      });
      onSuccess();
    })
    .catch(function (error) {
      dispatch({
        type: types.CONSOLIDATED_REPORT_DOWNLOAD_FAILURE_ACTION,
        payload: error,
      });
      onFailure();
    });
  // };
};

export const bulkReportMail = async (
  accessToken,
  dispatch,
  startdate,
  enddate,
  email,
  { onSuccess, onFailure }
) => {
  // return function (dispatch) {
  await axios
    .get(
      types.API_URL +
      `/bulkDownloadReport?startdate=${startdate}&enddate=${enddate}&email=${email}`
      ,
      { headers: { 'Authorization': `Bearer ${accessToken}` } }
    )
    .then(function (response) {
      dispatch({
        type: types.BULK_REPORT_MAIL_SUCCESS_ACTION,
        payload: response.data,
      });
      onSuccess();
    })
    .catch(function (error) {
      dispatch({
        type: types.BULK_REPORT_MAIL_FAILURE_ACTION,
        payload: error,
      });
      onFailure();
    });
  // };
};

export const ConsolidatedReportMail = async (
  accessToken,
  dispatch,
  fromDate,
  toDate,
  status,
  email,
  { onSuccess, onFailure }
) => {
  // return function (dispatch) {
  await axios
    .get(
      types.API_URL +
      `/mailConsolidatedReport?fromDate=${fromDate}&toDate=${toDate}&status=${status}&email=${email}`
      ,
      { headers: { 'Authorization': `Bearer ${accessToken}` } }
    )
    .then(function (response) {
      dispatch({
        type: types.CONSOLIDATED_REPORT_MAIL_SUCCESS_ACTION,
        payload: response.data,
      });
      onSuccess();
    })
    .catch(function (error) {
      dispatch({
        type: types.CONSOLIDATED_REPORT_MAIL_FAILURE_ACTION,
        payload: error,
      });
      onFailure();
    });
  // };
};

export const userExport = async (accessToken, dispatch) => {
  // return function (dispatch) {
  await axios
    .get(types.API_URL + `/allUsersExport`, {
      responseType: "blob",
      headers: { 'Authorization': `Bearer ${accessToken}` }
    })
    .then(function (response) {
      FileSaver.saveAs(response.data, `userExport.xlsx`);
      dispatch({
        type: types.USER_REPORT_DOWNLOAD_SUCCESS_ACTION,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatch({
        type: types.USER_REPORT_DOWNLOAD_FAILURE_ACTION,
        payload: error,
      });
    });
  // };
};

export const cityMasterExport = async (accessToken, dispatch) => {
  // return function (dispatch) {
  await axios
    .get(types.API_URL + `/cityMasterExport`, {
      responseType: "blob",
      headers: { 'Authorization': `Bearer ${accessToken}` }
    })
    .then(function (response) {
      FileSaver.saveAs(response.data, `cityMasterExport.xlsx`);
      dispatch({
        type: types.CITY_MASTER_DOWNLOAD_SUCCESS_ACTION,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatch({
        type: types.CITY_MASTER_DOWNLOAD_FAILURE_ACTION,
        payload: error,
      });
    });
  // };
};

export const DeleteCandidate = async (accessToken, dispatch, data, { onSuccess, onFailure }) => {
  // return function (dispatch) {
  await axios
    .post(types.API_URL + `/deleteAudit?masterId=${data}`, {}, { headers: { 'Authorization': `Bearer ${accessToken}` } })
    .then(function (response) {
      dispatch({
        type: types.MASTER_DATA_DELETE_SUCCESS_ACTION,
        payload: response.data,
      });
      onSuccess();
    })
    .catch(function (error) {
      dispatch({
        type: types.MASTER_DATA_DELETE_FAILURE_ACTION,
        payload: error,
      });
      onFailure();
    });
  // };
};

export const ClearUserSession = async (accessToken, dispatch, data, { onSuccess, onFailure }) => {
  // return function (dispatch) {
  await axios
    .post(types.API_URL + `/clearUserSession`, data, { headers: { 'Authorization': `Bearer ${accessToken}` } })
    .then(function (response) {
      dispatch({
        type: types.SESSION_CLEAR_SUCCESS_ACTION,
        payload: response.data,
      });
      onSuccess();
    })
    .catch(function (error) {
      dispatch({
        type: types.SESSION_CLEAR_FAILURE_ACTION,
        payload: error,
      });
      onFailure();
    });
  // };
};

export const GetStates = async (accessToken, dispatch, { onSuccess, onFailure }) => {
  // return function (dispatch) {
  await axios
    .get(types.API_URL + `/getStates`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
    .then(function (response) {
      dispatch({
        type: types.GET_STATES_SUCCESS_ACTION,
        payload: response.data,
      });
      onSuccess(response.data.states);
    })
    .catch(function (error) {
      dispatch({
        type: types.GET_STATES_FAILURE_ACTION,
        payload: error,
      });
      onFailure();
    });
  // };
};

export const RejectCases = async (accessToken, dispatch, data, { onSuccess, onFailure }) => {
  // return function (dispatch) {
  await axios
    .post(types.API_URL + `/rejectCases`, data, { headers: { 'Authorization': `Bearer ${accessToken}` } })
    .then(function (response) {
      dispatch({
        type: types.REJECT_CASES_SUCCESS_ACTION,
        payload: response.data,
      });
      onSuccess();
    })
    .catch(function (error) {
      dispatch({
        type: types.REJECT_CASES_FAILURE_ACTION,
        payload: error,
      });
      onFailure();
    });
  // };
};


export const GetRejectCases = async (accessToken, dispatch) => {
  // return function (dispatch) {
  await axios
    .get(types.API_URL + `/getRejectedCases`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
    .then(function (response) {
      dispatch({
        type: types.GET_REJECT_CASES_SUCCESS_ACTION,
        payload: response.data,
      });
    })
    .catch(function (error) {
      dispatch({
        type: types.GET_REJECT_CASES_FAILURE_ACTION,
        payload: error,
      });
    });
  // };
};

