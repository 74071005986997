import React, { useState } from 'react';
import { Button, Header, Form, Modal, TextArea } from 'semantic-ui-react'

function MailPopup(props) {
    const [state, setStateValue] = useState({
        open: props.open,
        mailIds: props.mailIds
    })
    const setState = (value = {}) => {
        setStateValue((old) => {
            return { ...old, ...value }
        })
    }

    const show = () => {
        setState({ open: true });
    }

    const sendAndClose = () => {
        setState({ mailIds: this.state.mailIds, open: false });
        props.sendMailAndClosePopup(this.state.mailIds);
    }

    const close = () => {
        setState({ open: false });
        props.closePopup();
    }

    const handleMailIdChange = (e) => {
        setState({ mailIds: e.target.value });
    }



    return (
        <div>
            <Modal size="mini" dimmer="blurring" open={state.open} onClose={close}>
                <Modal.Header>Enter email ids</Modal.Header>
                <Modal.Content>
                    <Form>
                        <TextArea placeholder="Enter multiple mail with semicolon" onChange={handleMailIdChange} style={{ width: 300, height: 200 }} />
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={close}>
                        Nope
                    </Button>
                    <Button positive icon='checkmark' labelPosition='right' content="Send" onClick={sendAndClose} />
                </Modal.Actions>
            </Modal>
        </div>
    )

}

export default MailPopup