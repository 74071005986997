import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, withRouter } from "react-router";
import { isLoggedIn } from "./../../util";
import { UploadCityMasterCsv, dismissLogs, initiateUpload, UploadMasterCsv, UploadPincodeMasterCsv, UploadResidenceMasterCsv, autoAssign, UploadResidenceMasterCsvClearance, downloadMasterUploadTemple } from "../../actions/index";
import { Button, Modal, Segment, Dimmer, Loader, Grid } from "semantic-ui-react";

function FileUpload(props) {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector((rdx) => rdx.auth)
  const accessToken = auth.accessToken
  const user = useSelector((rdx) => rdx.user)
  const islogin = auth.isLoggedIn
  const [state, setStateValue] = useState({
    isUploading: false,
    visible: true,
    masterCsvFile: null,
    cityCsvFile: null,
    pincodeCsvFile: null,
    residenceCsvFile: null,
    loading: false,
    showLog: false,
  })
  const setState = (value = {}) => {
    setStateValue((old) => {
      return { ...old, ...value }
    })
  }

  const handleDismiss = () => {
    setState({ visible: false });

    setTimeout(() => {
      setState({ visible: true });
    }, 2000);
  };

  const handleMasterFileUpload = (files) => {
    const file = files.target.files[0];
    setState({ masterCsvFile: file });
    files.target.files = null;
  };

  const handleCityFileUpload = (files) => {
    const file = files.target.files[0];
    setState({ cityCsvFile: file });
    files.target.files = null;
  };

  const handleResidenceFileUpload = (files) => {
    const file = files.target.files[0];
    setState({ residenceCsvFile: file });
    files.target.files = null;
  };

  const handlePincodeFileUpload = (files) => {
    const file = files.target.files[0];
    setState({ pincodeCsvFile: file });
    files.target.files = null;
  };

  const uploadMasterData = () => {
    initiateUpload(accessToken, dispatch, true);
    UploadResidenceMasterCsvClearance(accessToken, dispatch, {
      onSuccess: (res) => {
        if (res.status === true) {
          UploadMasterCsv(accessToken, dispatch, state.masterCsvFile, {
            onSuccess: (res) => {
              setState({ loading: false });
              // alert(res.data.message);
            },
            onFailure: () => {
              setState({ loading: false });
              alert("Wrong CSV file Uploaded");
            },
          });
          setState({ loading: true, showLog: true });
        }
        else {
          alert(res.message)
          setState({ loading: false });
        }
      },
      onFailure: () => {
        setState({ loading: false });
        alert("Issues while creating space to download. Please, try again Later");
      },
    })
  };

  const downloadMasterTemplate = (type) => {
    setState({ loading: true, showLog: true });
    downloadMasterUploadTemple(accessToken, dispatch, type, {
      onSuccess: (res) => {
        setState({ loading: false });
        // alert(res.data.message);
      },
      onFailure: () => {
        setState({ loading: false });
        alert("File Not Downloaded. Please, try again Later");
      },
    })
  }

  const uploadCityData = () => {
    initiateUpload(accessToken, dispatch, true);
    UploadCityMasterCsv(accessToken, dispatch, state.cityCsvFile, {
      onSuccess: (res) => {
        setState({ loading: false });
        alert(res.data.message);
      },
      onFailure: () => {
        setState({ loading: false });
        alert("Wrong CSV file Uploaded");
      },
    });
    setState({ loading: true, showLog: true });
  };

  const uploadPincodeData = () => {
    initiateUpload(accessToken, dispatch, true);
    UploadPincodeMasterCsv(accessToken, dispatch, state.pincodeCsvFile, {
      onSuccess: (res) => {
        setState({ loading: false });
      },
      onFailure: () => {
        setState({ loading: false });
        alert("Wrong CSV file Uploaded");
      },
    });
  };

  const uploadResidenceData = () => {
    initiateUpload(accessToken, dispatch, true);

    UploadResidenceMasterCsvClearance(accessToken, dispatch, {
      onSuccess: (res) => {
        if (res.status === true) {
          UploadResidenceMasterCsv(accessToken, dispatch, state.residenceCsvFile, {
            onSuccess: (res) => {
              setState({ loading: false });
              // alert(res.data.message);
            },
            onFailure: () => {
              setState({ loading: false });
              alert("Wrong CSV file Uploaded");
            },
          });
          setState({ loading: true, showLog: true });
        }
        else {
          alert(res.message)
          setState({ loading: false });
        }
      },
      onFailure: () => {
        setState({ loading: false });
        alert("Issues while creating space to download. Please, try again Later");
      },
    })
  };

  const autoAssignFn = () => {
    autoAssign(accessToken, dispatch, {
      onSuccess: () => {
        alert("Auto assigned Succeess");
        setState({ loading: false });
        alert("Success");
      },
      onFailure: () => {
        setState({ loading: false });
        alert("Auto Assign Failed");
      },
    });
    setState({ loading: true });
  };

  const closeLog = () => {
    setState({ showLog: false });
  };


  return (
    <div style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Dimmer active={state.loading}>
          <Loader size="massive">Uploading</Loader>
        </Dimmer>
        <Grid style={{ width: "100%" }}>
          <Grid.Row>
            <Grid.Column width={8}>
              <Segment.Group style={{ width: "100%" }}>
                <Segment>
                  <span
                    style={{
                      color: "teal",
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Upload Employee Master CSV File to add Employee Data
                  </span>
                </Segment>
                <Segment style={{ display: "flex", flexDirection: "row" }}>
                  <Grid style={{ width: "100%" }}>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <input
                          type={"file"}
                          accept=".csv"
                          style={{ minWidth: '100px' }}
                          onChange={handleMasterFileUpload}
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Button
                          // content="Upload Employee Data"
                          content="Upload"
                          icon="upload"
                          labelPosition="right"
                          color="blue"
                          // style={{ marginLeft: 20 }}
                          onClick={uploadMasterData}
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Button
                          content="Template"
                          // content="Upload Residence Data"
                          icon="download"
                          labelPosition="right"
                          color="brown"
                          // style={{ marginLeft: 20 }}
                          onClick={() => { downloadMasterTemplate("Employee") }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Segment.Group>
            </Grid.Column>
            <Grid.Column width={8}>
              <Segment.Group style={{ width: "100%" }}>
                <Segment>
                  <span
                    style={{
                      color: "teal",
                      fontFamily: "sans-serif",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    Upload Residence Master CSV File to add Residence Data
                  </span>
                </Segment>
                <Segment style={{ display: "flex", flexDirection: "row" }}>
                  <Grid style={{ width: "100%" }}>
                    <Grid.Row>
                      <Grid.Column width={8}>
                        <input
                          type={"file"}
                          accept=".csv"
                          onChange={handleResidenceFileUpload}
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Button
                          content="Upload"
                          // content="Upload Residence Data"
                          icon="upload"
                          labelPosition="right"
                          color="blue"
                          // style={{ marginLeft: 20 }}
                          onClick={uploadResidenceData}
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Button
                          content="Template"
                          // content="Upload Residence Data"
                          icon="download"
                          labelPosition="right"
                          color="brown"
                          // style={{ marginLeft: 20 }}
                          onClick={() => { downloadMasterTemplate("Residence") }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Segment.Group>
            </Grid.Column>
          </Grid.Row>

        </Grid>

        {state.showLog && (
          <Modal close={closeLog} open={auth.showLog}>
            <Modal.Header>Upload Report</Modal.Header>
            <Modal.Content>
              <div
                dangerouslySetInnerHTML={{ __html: auth.log }}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button color="black" onClick={closeLog}>
                OK
              </Button>
            </Modal.Actions>
          </Modal>
        )}
        {state.loading == false && <Loader inverted>Loading</Loader>}
      </div>
    </div>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.auth,
//     home: state.home,
//     controlPanel: state.controlPanel,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators(
//     {
//       UploadMasterCsv: UploadMasterCsv,
//       UploadPincodeMasterCsv: UploadPincodeMasterCsv,
//       UploadCityMasterCsv: UploadCityMasterCsv,
//       initiateUpload: initiateUpload,
//       dismissLogs: dismissLogs,
//       UploadResidenceMasterCsv: UploadResidenceMasterCsv,
//       UploadResidenceMasterCsvClearance: UploadResidenceMasterCsvClearance,
//       autoAssign: autoAssign,
//     },
//     dispatch
//   );
// };

// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(FileUpload)
// );
export default FileUpload
