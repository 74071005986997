import axios from 'axios';
import * as types from './types';
import * as FileSaver from "file-saver";
// import { useDispatch } from 'react-redux'
export const loginAction = async (accessToken, dispatch, { username, password, platform }) => {
    // const dispatch = useDispatch()
    // return function (dispatch) {
    await axios.post(types.API_URL + '/login', {
        loginId: username,
        password: password,
        platform: platform
    }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            if (response.data.message) {
                alert(response.data.message)
            }
            else if (response.data?.access_token) {
                dispatch({
                    type: types.LOGIN_SUCCESS_ACTION,
                    payload: response.data
                });
            }
        })
        .catch(function (error) {
            console.log("LOGIN ERROR", error)
            if (error.response.data.message) {
                alert(error.response.data.message)
            }
            dispatch({
                type: types.LOGIN_FAILURE_ACTION,
                payload: error
            });
        });
    // };
}

export const logoutAction = async (accessToken, dispatch, email) => {
    // return function (dispatch) {
    await axios.post(types.API_URL + `/logout`, { loginId: email }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function () {
            localStorage.clear()
            dispatch({
                type: types.LOGOUT_SUCCESS_ACTION,
            });
        })
        .catch(function (error) {
            localStorage.clear()
            dispatch({
                type: types.LOGIN_FAILURE_ACTION,
                payload: error
            });
        });
    // };

}




export const fetchUseraction = async (accessToken, dispatch, { onSuccess, onFailure }) => {
    // return function (dispatch) {
    await axios.get(types.API_URL + `/getAllUsers`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            dispatch({
                type: types.CONTROL_PANEL_SUCCESS_ACTION,
                payload: response.data
            });
            onSuccess()
        })
        .catch(function (error) {
            dispatch({
                type: types.CONTROL_PANEL_FAILURE_ACTION,
                payload: error
            });
            onFailure()
        });
    // };

}

export const adduser = async (accessToken, dispatch, displayName, email, loginId, mobileNumber, idcard, userPhoto, remarksAccess = false, rotateAccess = false, loaName, loaAddress, role, jurisdiction, teamLeaderId, teamMemberId, { onSuccess, onFailure }) => {
    // return function (dispatch) {
    await axios.post(
        types.API_URL + `/addUsers`,
        { displayName: displayName, email: email, loginId: loginId, mobileNumber: mobileNumber, idCard: idcard, photo: userPhoto, remarksAccess: remarksAccess, rotateAccess: rotateAccess, loaName: loaName, loaAddress: loaAddress, role: role, jurisdiction: jurisdiction, teamLeaderId: teamLeaderId, teamMemberId: teamMemberId },
        { headers: { 'Authorization': `Bearer ${accessToken}` } }
    )
        .then(function (response) {
            alert(response.data.message)
            if (response.data.success == true) {
                dispatch({
                    type: types.ADD_USER_SUCCESS_ACTION,
                    payload: response.data
                });
            }
            onSuccess(response);
        })
        .catch(function (error) {
            // dispatch({
            //     type: types.ADD_USER_FAILURE_ACTION,
            //     payload: error
            // });
            onFailure(error)
        });
    // };

}
export const EditUser = async (accessToken, dispatch, displayName, email, loginId, mobileNumber, idcard, userPhoto, remarksAccess = false, rotateAccess = false, loaName, loaAddress, role, jurisdiction, user_id, { onSuccess, onFailure }, teamLeaderId, teamMemberId) => {
    // return function (dispatch) {
    await axios.post(
        types.API_URL + `/editUsers`,
        { displayName: displayName, email: email, loginId: loginId, mobileNumber: mobileNumber, idCard: idcard, photo: userPhoto, remarksAccess: remarksAccess, rotateAccess: rotateAccess, loaName: loaName, loaAddress: loaAddress, role: role, jurisdiction: jurisdiction, _id: user_id, teamLeaderId, teamMemberId },
        { headers: { 'Authorization': `Bearer ${accessToken}` } }
    )
        .then(function (response) {
            // alert(response.data.message)
            dispatch({
                type: types.EDIT_USER_SUCCESS_ACTION,
                payload: response.data
            });
            onSuccess(response);
        })
        .catch(function (error) {
            dispatch({
                type: types.EDIT_USER_FAILURE_ACTION,
                payload: error
            });
            onFailure(error);
        });
    // };

}
export const DeleteUser = async (accessToken, dispatch, user_id, { onSuccess, onFailure }) => {
    // return function (dispatch) {
    await axios.post(types.API_URL + `/deleteUsers`, { _id: user_id }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            alert(response.data.message)
            dispatch({
                type: types.DELETE_USER_SUCCESS_ACTION,
                payload: response.data
            });
            onSuccess();
        })
        .catch(function (error) {
            dispatch({
                type: types.DELETE_USER_FAILURE_ACTION,
                payload: error
            });
            onFailure();
        });
    // };

}



export const fetchId = async (accessToken, dispatch, photo, { onSuccess, onFailure }) => {
    let file = new FormData();
    file.append('photo', photo)
    // return function (dispatch) {
    await axios.post(types.API_URL + `/upload/idCard`, file, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            alert("Photo Upload Successfully")
            dispatch({
                type: types.UPLOAD_ID_CARD_SUCCESS_ACTION,
                payload: response.data
            });
            onSuccess(response.data);
        })
        .catch(function (error) {
            alert("Photo not uploaded")
            dispatch({
                type: types.UPLOAD_ID_CARD_FAILURE_ACTION,
                payload: error
            });
            onFailure()
        });
    // };

}


export const UploadImages = async (accessToken, dispatch, photo, { onSuccess, onFailure }) => {
    let file = new FormData();
    file.append('photo', photo)
    // return function (dispatch) {
    await axios.post(types.API_URL + `/upload/auditsPhoto`, file, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            console.log('response', response);
            dispatch({
                type: types.UPLOAD_ID_CARD_SUCCESS_ACTION,
                payload: response.data
            });
            onSuccess(response.data);
        })
        .catch(function (error) {
            console.log('error', error);
            alert("Photo not uploaded")
            dispatch({
                type: types.UPLOAD_ID_CARD_FAILURE_ACTION,
                payload: error
            });
            onFailure()
        });
    // };

}

export const deleteImages = async (accessToken, dispatch, objectId = "", { onSuccess, onFailure }) => {
    await axios.post(types.API_URL + `/document/remove`, { objectId }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            console.log('response', response);
            onSuccess(response.data);
        })
        .catch(function (error) {
            console.log('error', error);
            alert(error?.response?.data?.message ?? "Photo Not Deleted")
            onFailure()
        });
}

export const changeRotationImage = async (accessToken, dispatch, masterDataId = "", questionId = "", objectId = "", rotation = "", { onSuccess, onFailure }) => {
    await axios.post(types.API_URL + `/document/changeOrientation`, { masterDataId, questionId, objectId, rotation }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            console.log('response', response);
            onSuccess(response.data);
        })
        .catch(function (error) {
            console.log('error', error);
            alert(error?.response?.data?.message ?? "Photo Not Rotated")
            onFailure()
        });
}

export const saveRotationImage = async (accessToken, dispatch, masterDataId = "", objectData = "", { onSuccess, onFailure }) => {
    await axios.post(types.API_URL + `/document/updateOrientation`, { masterDataId, objectData }, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            console.log('response', response);
            onSuccess(response.data);
        })
        .catch(function (error) {
            console.log('error', error.response.data.message);
            alert(error?.response?.data?.message ?? "Photo Not Saved")
            onFailure()
        });
}

export const fetchJurisdiction = async (accessToken, dispatch) => {
    // return function (dispatch) {
    await axios.get(types.API_URL + `/getJurisdiction`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            dispatch({
                type: types.GET_JURISDICTION_SUCCESS_ACTION,
                payload: response.data
            });
        })
        .catch(function (error) {
            dispatch({
                type: types.GET_JURISDICTION_FAILURE_ACTION,
                payload: error
            });
        });
    // };

}


export const selectMenuAction = async (accessToken, dispatch, selectedMenu) => {
    await dispatch({
        type: types.SELECT_MENU_ACTION,
        payload: selectedMenu
    })
}


export const initiateUpload = async (accessToken, dispatch, value) => {
    // return function (dispatch) {
    await dispatch({
        type: types.INITIATE_UPLOAD,
        payload: value
    });
    // };
}

export const dismissLogs = async (accessToken, dispatch, value) => {
    // return function (dispatch) {
    await dispatch({
        type: types.DISMISS_LOG,
        payload: value
    });
    // };
}


export const UploadMasterCsv = async (accessToken, dispatch, file, { onSuccess, onFailure }) => {
    var formData = new FormData();
    formData.append('file', file, file.name);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
        },
        responseType: "blob",
    }
    // return function (dispatch) {
    await axios.post(types.API_URL + '/uploadEmployeeMasterData', formData, config)
        .then(function (response) {
            FileSaver.saveAs(response.data, `EmployeeMasterExport.xlsx`);
            dispatch({
                type: types.UPLOAD_MASTER_CSV_SUCCESS,
                payload: "File upload Success"
            });
            onSuccess(response);
        })
        .catch(function (error) {
            dispatch({
                type: types.UPLOAD_MASTER_CSV_FAILURE,
                payload: error
            });
            onFailure();
        });
    // };
}


export const UploadCityMasterCsv = async (accessToken, dispatch, file, { onSuccess, onFailure }) => {
    var formData = new FormData();
    formData.append('file', file, file.name);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
        }
    }
    // return function (dispatch) {
    await axios.post(types.API_URL + '/uploadCityMaster', formData, config)
        .then(function (response) {
            dispatch({
                type: types.UPLOAD_CITY_MASTER_CSV_SUCCESS,
                payload: response.data
            });
            onSuccess(response);
        })
        .catch(function (error) {
            dispatch({
                type: types.UPLOAD_CITY_MASTER_CSV_FAILURE,
                payload: error
            });
            onFailure();
        });
    // };
}


export const UploadPincodeMasterCsv = async (accessToken, dispatch, file, { onSuccess, onFailure }) => {
    var formData = new FormData();
    formData.append('file', file, file.name);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
        }
    }
    // return function (dispatch) {
    await axios.post(types.API_URL + '/uploadPincodeMaster', formData, config)
        .then(function (response) {
            dispatch({
                type: types.UPLOAD_PINCODE_MASTER_CSV_SUCCESS,
                payload: response.data
            });
            onSuccess(response);
        })
        .catch(function (error) {
            dispatch({
                type: types.UPLOAD_PINCODE_MASTER_CSV_FAILURE,
                payload: error
            });
            onFailure();
        });
    // };
}


export const UploadResidenceMasterCsvClearance = async (accessToken, dispatch, { onSuccess, onFailure }) => {
    // return function (dispatch) {
    await axios.get(types.API_URL + `/canUploadMasterData`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            dispatch({
                type: types.UPLOAD_RESIDENCE_MASTER_CSV_CLEARANCE_SUCCESS,
                payload: response.data
            });
            onSuccess(response.data);
        })
        .catch(function (error) {
            dispatch({
                type: types.UPLOAD_RESIDENCE_MASTER_CSV_CLEARANCE_FAILURE,
                payload: error
            });
            onFailure();
        });

    // };

}

export const UploadResidenceMasterCsv = async (accessToken, dispatch, file, { onSuccess, onFailure }) => {
    var formData = new FormData();
    formData.append('file', file, file.name);
    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
        },
        responseType: "blob",
    }
    // return function (dispatch) {
    await axios.post(types.API_URL + '/uploadResidenceMasterData', formData, config)
        .then(function (response) {
            FileSaver.saveAs(response.data, `ResidenceMasterExport.xlsx`);
            dispatch({
                type: types.UPLOAD_RESIDENCE_MASTER_CSV_SUCCESS,
                payload: "File upload Success"
            });
            onSuccess(response);
        })
        .catch(function (error) {
            dispatch({
                type: types.UPLOAD_RESIDENCE_MASTER_CSV_FAILURE,
                payload: error
            });
            onFailure();
        });
    // };
}

export const downloadMasterUploadTemple = async (accessToken, dispatch, type, { onSuccess, onFailure }) => {
    const config = {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        },
        responseType: "blob",
    }
    // return function (dispatch) {
    await axios.get(types.API_URL + `/getMasterDataTemplate?templateType=${type}`, config)
        .then(function (response) {
            FileSaver.saveAs(response.data, `${type}MasterUploadTemplate.csv`);
            // dispatch({
            //     type: types.DOWNLOAD_RESIDENCE_TEMPLATE_CSV_SUCCESS,
            //     payload: "File upload Success"
            // });
            onSuccess(response);
        })
        .catch(function (error) {
            // dispatch({
            //     type: types.DOWNLOAD_RESIDENCE_TEMPLATE_CSV_FAILURE,
            //     payload: error
            // });
            onFailure();
        });
    // };
}


export const autoAssign = async (accessToken, dispatch, { onSuccess, onFailure }) => {
    // return function (dispatch) {
    await axios.post(types.API_URL + `/autoAssign`, {}, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            dispatch({
                type: types.AUTO_ASSIGN_SUCCESS_ACTION,
                payload: response.data
            });
            onSuccess();
        })
        .catch(function (error) {
            dispatch({
                type: types.AUTO_ASSIGN_FAILURE_ACTION,
                payload: error
            });
            onFailure();
        });
    // };
}


export const resetpassword = async (accessToken, dispatch, ResetPassword) => {
    // return function (dispatch) {
    await axios.post(types.API_URL + '/resetPassword', ResetPassword, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            alert(response.data.message)
            dispatch({
                type: types.RESET_PASSWORD_SUCCESS_ACTION,
                payload: response.data
            });
        })
        .catch(function (error) {
            dispatch({
                type: types.RESET_PASSWORD_FAILURE_ACTION,
                payload: error
            });
        });
    // };
}

export const forgotPassword = async (accessToken, dispatch, data) => {
    // return function (dispatch) {
    await axios.post(types.API_URL + '/forgotPassword', data, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            alert(response.data.message)
            dispatch({
                type: types.FORGOT_PASSWORD_SUCCESS_ACTION,
                payload: response.data
            });
        })
        .catch(function (error) {
            dispatch({
                type: types.FORGOT_PASSWORD_FAILURE_ACTION,
                payload: error
            });
        });
    // };
}


export const AssignUseraction = async (accessToken, dispatch) => {
    // return function (dispatch) {
    await axios.get(types.API_URL + `/getUsersAssign`, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            dispatch({
                type: types.ASSIGN_USERS_SUCCESS_ACTION,
                payload: response.data
            });
        })
        .catch(function (error) {
            dispatch({
                type: types.ASSIGN_USERS_FAILURE_ACTION,
                payload: error
            });
        });
    // };

}

export const CustomerFeedbackaction = async (accessToken, dispatch, data, matrixRefNo, { onSuccess, onFailure }) => {
    // return function (dispatch) {
    await axios.post(types.API_URL + `/saveFeedbackform?matrixRefNo=${matrixRefNo}`, data, { headers: { 'Authorization': `Bearer ${accessToken}` } })
        .then(function (response) {
            dispatch({
                type: types.FEEDBACK_SUCCESS_ACTION,
                payload: response.data
            });
            onSuccess();
        })
        .catch(function (error) {
            dispatch({
                type: types.FEEDBACK_FAILURE_ACTION,
                payload: error
            });
            onFailure();
        });
    // };

}