import React, { useEffect, useRef, useState } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Icon, Segment, Checkbox, Button, Modal, Statistic, Grid, Dropdown, Label, Image, ModalActions, Step, Divider, Dimmer, Loader, Form } from "semantic-ui-react";
import { userAction, Reportdownload, GetRejectCases } from "../../actions/users_action";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, withRouter } from "react-router";
import selectTable from "react-table-v6/lib/hoc/selectTable";
import Questions from "../Maincomponents/questionView.jsx";
import { isLoggedIn } from "../../util";

const CheckboxTable = selectTable(ReactTable);

function RejectedCases() {
    const navigation = useNavigate()
    const dispatch = useDispatch()
    const auth = useSelector((rdx) => rdx.auth)
    const accessToken = auth.accessToken
    const user = useSelector((rdx) => rdx.user)
    const islogin = auth.isLoggedIn
    // let checkboxTable = null
    const setEbfcCheckboxRef = useRef()
    // this.checkboxTable = null;
    const [state, setStateValue] = useState({
        audits: [],
        EmployeeData: [],
        ResidenceData: [],
        value: "ALL",
        mainView: true,
        id: "",
        auditsType: "",
        modal: false,
        userId: [],
        email: [],
        selectAll: false,
        selection: [],
        fromDate: null,
        toDate: null,
        firstDate: null,
        city: "",
        status: "",
        showDeleteConfirmModal: false,
        showRejectConfirmModal: false,
        rejectedData: "",
        deletedData: "",
        allData: [],
        storeName: "",
        typeAns: "",
        filterData: [],
        filterCheck: false,
        loading: false,
        colorCode: '',
        resonForRejection: ''
    })
    const setState = (value = {}) => {
        setStateValue((old) => {
            return { ...old, ...value }
        })
    }

    // this.setEbfcCheckboxRef = (element) => {
    //     this.checkboxTable = element;
    // };

    useEffect(() => {
        if (user.alldata != undefined) {
            setState({ allData: user.alldata });
        }
        GetRejectCases(accessToken,dispatch);
    }, [])
    useEffect(() => {
        if (user.alldata != undefined) {
            setState({ allData: user.alldata });
        }
    }, [user.alldata])


    const back = () => {
        setState({
            mainView: true,
        });
    };

    // handleTableViewAuditClick = (store) => {
    //     // console.log(store, "store");
    //     this.props.fetchQuestions({ masterDataId: store._id });
    //     this.setState({
    //         mainView: false,
    //         id: store._id,
    //         auditsType: store.auditsType,
    //         storeName: store.clientName,
    //     });
    // };

    const downloadReport = (template) => {
        console.log(template, "downloadReport");
        // return
        setState({ loading: true });
        Reportdownload(
            dispatch,
            template._id,
            template.refNo,
            template.checkType,
            template.auditsType,
            'reject',
            {
                onSuccess: () => {
                    setState({ loading: false });
                },
                onFailure: () => {
                    setState({ loading: false });
                },
            }
        );
    };


    console.log(user.rejectedCases, 'cases')
    var users = auth.allusers;
    let Residencecolumns = [
        {
            Header: "ClientName",
            accessor: "clientName",
            style: { textAlign: "center", cursor: "pointer" },
            Cell: (row) => (
                <AuditTableCell
                    row={row.original}
                    text={row.original.clientName}
                // onClick={this.handleTableViewAuditClick}
                />
            ),
        },
        {
            Header: "Matrix Ref No",
            accessor: "refNo",
            style: { textAlign: "center", cursor: "pointer" },
            Cell: (row) => (
                <AuditTableCell
                    row={row.original}
                    text={row.original.refNo}
                // onClick={this.handleTableViewAuditClick}
                />
            ),
        },
        {
            Header: "Check Type",
            accessor: "checkType",
            style: { textAlign: "center", cursor: "pointer" },
            Cell: (row) => (
                <AuditTableCell
                    row={row.original}
                    text={row.original.checkType}
                // onClick={this.handleTableViewAuditClick}
                />
            ),
        },
        {
            Header: "City",
            accessor: "city",
            style: { textAlign: "center", cursor: "pointer" },
            Cell: (row) => (
                <AuditTableCell
                    row={row.original}
                    text={row.original.city}
                // onClick={this.handleTableViewAuditClick}
                />
            ),
        },
        {
            Header: "Rejected Date",
            accessor: "rejectedDate",
            style: { textAlign: "center", cursor: "pointer" },
            Cell: (row) => (
                <AuditTableCell
                    row={row.original}
                    text={row.original.assignedAt ? row.original.assignedAt.substring(0, 10) : ''}
                // onClick={this.handleTableViewAuditClick}
                />
            ),
        },
        {
            Header: "Rejected Reason",
            accessor: "rejectedReason",
            style: { textAlign: "center", cursor: "pointer" },
            Cell: (row) => (
                <AuditTableCell
                    row={row.original}
                    text={row.original.rejectedReason}
                // onClick={this.handleTableViewAuditClick}
                />
            ),
        },
        {
            Header: "Color Code",
            accessor: "distance_color",
            style: { textAlign: "center", cursor: "pointer" },
            Cell: (row) => (
                <AuditTableCell
                    row={row.original}
                    text={row.original.distance_color ? row.original.distance_color : ''}
                // onClick={this.handleTableViewAuditClick}
                />
            ),
        },
        {
            Header: "Address",
            accessor: "address",
            style: { textAlign: "center", cursor: "pointer" },
            Cell: (row) => (
                <AuditTableCell
                    row={row.original}
                    text={row.original.address}
                // onClick={this.handleTableViewAuditClick}
                />
            ),
        },
        {
            Header: "Report",
            accessor: "report",
            style: { textAlign: "center", cursor: "pointer" },
            Cell: (row) => {
                if (row.original._id) {
                    return (
                        <Icon
                            size="large"
                            color="red"
                            name="file pdf outline"
                            onClick={() => downloadReport(row.original)}
                        />
                    );
                }
            },
        }
    ];

    return (
        <div
            style={{
                flexGrow: 1,
                display: "flex",
                flexFlow: "column",
                height: "86vh",
                overflowY: "scroll",
                overflowX: "hidden",
            }}
        >
            <Dimmer active={state.loading}>
                <Loader>Downloading Report</Loader>
            </Dimmer>
            {state.mainView && (
                <div>

                    <div style={{ display: "flex", flexGrow: 1, flexFlow: "column" }}>
                        <CheckboxTable
                            noDataText="We couldn't find anything"
                            data={
                                user.rejectedCases !== undefined ? user.rejectedCases : []
                            }
                            columns={Residencecolumns}
                            style={{
                                height: "85%",
                                width: "90vw%",
                                marginLeft: 10,
                                marginTop: 20,
                            }}
                            filterable={true}
                            myRef={setEbfcCheckboxRef}
                            // toggleSelection={this.toggleSelection}
                            selectType="checkbox"
                            defaultPageSize={20}
                            sortable={true}
                        />
                    </div>
                </div>
            )}

            {!state.mainView && (
                <Questions
                    onClose={back}
                    auditsType={state.auditsType}
                    id={state.id}
                    storeName={state.storeName}
                />
            )}
        </div>
    );
}


function AuditTableCell(props) {
    function onClick() {
        if (props?.onClick) props.onClick(props.row)
    }
    return (
        <div style={props.style} onClick={onClick}>
            {props.text}
        </div>
    );
}

// const mapStateToProps = (state) => {
//     return {
//         auth: state.auth,
//         user: state.user,
//     };
// };

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators(
//         {
//             users: userAction,
//             Reportdownload: Reportdownload,
//             GetRejectCases: GetRejectCases
//         },
//         dispatch
//     );
// };

// export default withRouter(
//     connect(mapStateToProps, mapDispatchToProps)(RejectedCases)
// );
export default RejectedCases