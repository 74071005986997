import axios from 'axios';
import config from '../config.js';

export const API_URL = config['api_url'];
export const STORAGE_URL = config['Storagebase_url'];

axios.defaults.baseURL = API_URL;

export const LOGIN_SUCCESS_ACTION = 'login_success';
export const LOGIN_FAILURE_ACTION = 'login_failure';
export const LOGOUT_SUCCESS_ACTION = 'logout_sucess';
export const LOGOUT_FAILURE_ACTION = 'logout_failure';
export const RESET_PASSWORD_SUCCESS_ACTION = "RESET_PASSWORD_SUCCESS_ACTION";
export const RESET_PASSWORD_FAILURE_ACTION = "RESET_PASSWORD_FAILURE_ACTION";
export const FORGOT_PASSWORD_SUCCESS_ACTION = "FORGOT_PASSWORD_SUCCESS_ACTION";
export const FORGOT_PASSWORD_FAILURE_ACTION = "FORGOT_PASSWORD_FAILURE_ACTION";
export const SET_EMAIL = 'set_email';
export const SELECT_MENU_ACTION = 'select_menu';
export const INITIATE_UPLOAD = "INITIATE_UPLOAD";
export const DISMISS_LOG = "DISMISS_LOG";
export const UPLOAD_MASTER_CSV_SUCCESS = "UPLOAD_MASTER_CSV_SUCCESS";
export const UPLOAD_MASTER_CSV_FAILURE = "UPLOAD_MASTER_CSV_FAILURE";
export const UPLOAD_CITY_MASTER_CSV_SUCCESS = "UPLOAD_CITY_MASTER_CSV_SUCCESS";
export const UPLOAD_CITY_MASTER_CSV_FAILURE = "UPLOAD_CITY_MASTER_CSV_FAILURE";
export const UPLOAD_PINCODE_MASTER_CSV_SUCCESS = "UPLOAD_PINCODE_MASTER_CSV_SUCCESS";
export const UPLOAD_PINCODE_MASTER_CSV_FAILURE = "UPLOAD_PINCODE_MASTER_CSV_FAILURE";

export const CONTROL_PANEL_SUCCESS_ACTION = 'control_panel_success_action';
export const CONTROL_PANEL_FAILURE_ACTION = 'control_panel_failure_action';

export const ADD_USER_SUCCESS_ACTION = 'add_user_success';
export const ADD_USER_FAILURE_ACTION = 'add_user_failure';
export const EDIT_USER_SUCCESS_ACTION = 'edit_user_success';
export const EDIT_USER_FAILURE_ACTION = 'edit_user_failure';
export const DELETE_USER_SUCCESS_ACTION = 'delete_user_success';
export const DELETE_USER_FAILURE_ACTION = 'delete_user_failure';
export const UPLOAD_ID_CARD_SUCCESS_ACTION = 'upload_id_card_success';
export const UPLOAD_ID_CARD_FAILURE_ACTION = 'upload_id_card_failure';
export const GET_JURISDICTION_SUCCESS_ACTION = 'get_jurisdiction_success';
export const GET_JURISDICTION_FAILURE_ACTION = 'get_jurisdiction_failure';
export const ASSIGN_AUDITS_SUCCESS_ACTION = 'assign_audits_success';
export const ASSIGN_AUDITS_FAILURE_ACTION = 'assign_audits_failure';

export const USERLIST_SUCCESS_ACTION = 'userlist_success';
export const USERLIST_FAILURE_ACTION = 'userlist_failure';

export const FILTER_SUCCESS_ACTION = 'filter_success';
export const FILTER_FAILURE_ACTION = 'filter_failure';
export const REPORT_DOWNLOAD_SUCCESS_ACTION = 'report_download_success';
export const REPORT_DOWNLOAD_FAILURE_ACTION = 'report_download_failure';
export const BULK_REPORT_MAIL_SUCCESS_ACTION = "BULK_REPORT_MAIL_SUCCESS_ACTION";
export const BULK_REPORT_MAIL_FAILURE_ACTION = "BULK_REPORT_MAIL_FAILURE_ACTION";
export const CONSOLIDATED_REPORT_DOWNLOAD_SUCCESS_ACTION = "CONSOLIDATED_REPORT_DOWNLOAD_SUCCESS_ACTION";
export const CONSOLIDATED_REPORT_DOWNLOAD_FAILURE_ACTION = "CONSOLIDATED_REPORT_DOWNLOAD_FAILURE_ACTION";
export const CONSOLIDATED_REPORT_MAIL_SUCCESS_ACTION = "CONSOLIDATED_REPORT_MAIL_SUCCESS_ACTION";
export const CONSOLIDATED_REPORT_MAIL_FAILURE_ACTION = "CONSOLIDATED_REPORT_MAIL_FAILURE_ACTION";

export const UPLOAD_RESIDENCE_MASTER_CSV_CLEARANCE_SUCCESS = "UPLOAD_RESIDENCE_MASTER_CSV_CLEARANCE_SUCCESS";
export const UPLOAD_RESIDENCE_MASTER_CSV_CLEARANCE_FAILURE = "UPLOAD_RESIDENCE_MASTER_CSV_CLEARANCE_FAILURE";
export const UPLOAD_RESIDENCE_MASTER_CSV_SUCCESS = "UPLOAD_RESIDENCE_MASTER_CSV_SUCCESS";
export const UPLOAD_RESIDENCE_MASTER_CSV_FAILURE = "UPLOAD_RESIDENCE_MASTER_CSV_FAILURE";
export const DOWNLOAD_RESIDENCE_TEMPLATE_CSV_SUCCESS = "DOWNLOAD_RESIDENCE_TEMPLATE_CSV_SUCCESS";
export const DOWNLOAD_RESIDENCE_TEMPLATE_CSV_FAILURE = "DOWNLOAD_RESIDENCE_TEMPLATE_CSV_FAILURE";
export const AUTO_ASSIGN_SUCCESS_ACTION = "AUTO_ASSIGN_SUCCESS_ACTION";
export const AUTO_ASSIGN_FAILURE_ACTION = "AUTO_ASSIGN_FAILURE_ACTION";


export const FETCH_QUESTIONS_SUCCESS_ACTION = 'fetch_question_success';
export const FETCH_QUESTIONS_FAILURE_ACTION = 'fetch_question_failure';

export const FETCH_ID_SUCCESS_ACTION = 'fetch_id_success';
export const FETCH_ID_FAILURE_ACTION = 'fetch_id_failure';

export const SUBMIT_AUDITS_SUCCESS_ACTION = 'submit_audits_success';
export const SUBMIT_AUDITS_FAILURE_ACTION = 'submit_audits_failure';
export const USER_REPORT_DOWNLOAD_SUCCESS_ACTION = "USER_REPORT_DOWNLOAD_SUCCESS_ACTION";
export const USER_REPORT_DOWNLOAD_FAILURE_ACTION = "USER_REPORT_DOWNLOAD_FAILURE_ACTION";
export const ASSIGN_USERS_SUCCESS_ACTION = "ASSIGN_USERS_SUCCESS_ACTION";
export const ASSIGN_USERS_FAILURE_ACTION = "ASSIGN_USERS_FAILURE_ACTION";

export const CITY_MASTER_DOWNLOAD_SUCCESS_ACTION = "CITY_MASTER_DOWNLOAD_SUCCESS_ACTION";
export const CITY_MASTER_DOWNLOAD_FAILURE_ACTION = "CITY_MASTER_DOWNLOAD_FAILURE_ACTION";
export const MASTER_DATA_DELETE_SUCCESS_ACTION = "MASTER_DATA_DELETE_SUCCESS_ACTION";
export const MASTER_DATA_DELETE_FAILURE_ACTION = "MASTER_DATA_DELETE_FAILURE_ACTION";
export const REJECT_CASES_SUCCESS_ACTION = "REJECT_CASES_SUCCESS_ACTION";
export const REJECT_CASES_FAILURE_ACTION = "REJECT_CASES_FAILURE_ACTION";
export const GET_REJECT_CASES_SUCCESS_ACTION = "GET_REJECT_CASES_SUCCESS_ACTION";
export const GET_REJECT_CASES_FAILURE_ACTION = "GET_REJECT_CASES_FAILURE_ACTION";
export const SESSION_CLEAR_SUCCESS_ACTION = "SESSION_CLEAR_SUCCESS_ACTION";
export const SESSION_CLEAR_FAILURE_ACTION = "SESSION_CLEAR_FAILURE_ACTION";
export const GET_STATES_SUCCESS_ACTION = "GET_STATES_SUCCESS_ACTION";
export const GET_STATES_FAILURE_ACTION = "GET_STATES_FAILURE_ACTION";
export const FEEDBACK_SUCCESS_ACTION = "FEEDBACK_SUCCESS_ACTION";
export const FEEDBACK_FAILURE_ACTION = "FEEDBACK_FAILURE_ACTION";