import React, { useEffect, useRef, useState } from "react";
import "react-table-v6/react-table.css";
import { Icon, Segment, Button, Modal, Grid, Dropdown, Image, Input, TextArea } from "semantic-ui-react";
import { saveAuditRemarks, submitAudits } from "../../actions/users_action";
import { useSelector, useDispatch } from "react-redux";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  UploadImages,
  deleteImages,
  changeRotationImage,
  saveRotationImage
} from "../../actions/index";
import { isAdmin } from "../../util";
import moment from "moment";


function Questions(props) {
  let myRef = useRef()
  let questionRef = useRef()
  const dispatch = useDispatch()
  const auth = useSelector((rdx) => rdx.auth)
  const accessToken = auth.accessToken
  const remarksAccess = auth.remarksAccess
  const rotateAccess = auth.rotateAccess
  const user = useSelector((rdx) => rdx.user)
  const islogin = auth.isLoggedIn
  const isAdminUser = isAdmin(auth)
  const [state, setStateValue] = useState({
    EditView: false,
    disabled: false,
    editedAudits: [],
    editedQues: [],
    imageModal: false,
    rotateModal: false,
    rotateData: {
      masterDataId: props.id ?? "",
      objectData: "",
      presignedUrl: "",
      questionId: "",
      objectId: "",
      quesIndex: "",
      photoIndex: "",
    },
    zoomimage: "",
    newsign: false,
    localImageUrl: "",
    loading: false,
    isRejectedCase: true
  })
  const setState = (value = {}) => {
    setStateValue((old) => {
      return { ...old, ...value }
    })
  }


  useEffect(() => {
    setState({ isRejectedCase: true })
    if (user.questions != undefined) {
      questionRef.current = JSON.stringify(user.questions)
      setState({ editedAudits: user.questions, isRejectedCase: user.questions.status == "Rejected" });
    }
    // console.log("QUESTRIONS",user.questions)
  }, [user.questions])


  const open = () => {
    setState({
      EditView: true,
      disabled: true,
    });
  };
  const cancel = () => {
    setState({
      EditView: false,
      disabled: false,
    });
  };
  const zoom = (url) => {
    // console.log(url, 'url')
    setState({
      imageModal: true,
      zoomimage: url,
    });
  };
  const closeimage = () => {
    setState({
      imageModal: false,
      rotateModal: false,
    });
  };
  const Newsign = () => {
    setState({
      newsign: true,
    });
  };
  const back = () => {
    setState({
      newsign: false,
    });
  };
  const clear = () => {
    myRef.current.clear();
  };
  const changeAnswer = (question, e) => {
    // let data = state.editedAudits?.audits;
    // let data = state.editedQues;
    let masterDataId = props.id;
    let auditsType = props.auditsType;
    let answer = "";
    var audit = state.editedAudits.audits;

    if (question.answerType === "text") {
      answer = e.target.value;
      audit.map((q) => {
        if (q.questionId == question.questionId) {
          q.answer = answer;
        }
      });
    }
    if (question.answerType === "options") {
      answer = e;
      audit.map((q) => {
        if (q.questionId == question.questionId) {
          q.answer = answer;
        }
      });
    }

    if (question.answerType == "date") {
      answer = e;
      audit.map((q) => {
        if (q.questionId == question.questionId) {
          q.answer = answer;
        }
      });
    }

    if (question.answerType == "photo") {
      console.log(state.idCard, 'local')
      audit.map((q) => {
        if (q.questionId == question.questionId) {
          q.photos.push(state.idCard);
          q.localPic.push({ "uri": state.idCardUrl })
          // let data = {}
          // data.url = this.state.localImageUrl.name
        }
      });

      // submitAudits(accessToken,dispatch, audit, auditsType, masterDataId);
      // alert("photo updated successfully")
      // fetchQuestions(accessToken,dispatch, { masterDataId: props.id });
    }

    setState({
      editedQues: audit,
      // editedAudits: user.questions
    });

    // fetchQuestions(accessToken,dispatch, { masterDataId: props.id });
  };
  const save = (isNotDisable) => {
    let auditsType = props?.auditsType ?? "";
    let masterDataId = props?.id ?? "";
    let audits = state.editedQues;
    console.log('audits:', audits, "masterDataId:", masterDataId, 'auditsType:', auditsType)
    if (audits.length > 0 && isAdminUser) {
      submitAudits(accessToken, dispatch, audits, auditsType, masterDataId);
    } else if (audits.length > 0 && auditsType == "Employee") {
      let remarksfr = audits.filter((fr) => ((fr.answerType == "text") && (fr.question == 'Remarks')))
      let remarks = remarksfr[0]['answer']
      console.log("remarksfr", remarksfr, remarks, masterDataId, auditsType)
      saveAuditRemarks(accessToken, dispatch, remarks, masterDataId);
    }
    if (isNotDisable == true) {
      return
    }

    setState({
      EditView: false,
      disabled: false,
    });
  };

  const onPhotoSelect = (ques, e) => {
    console.log(e.target.files, 'files')
    let photo = e.target.files[0];
    UploadImages(accessToken, dispatch, photo, {
      onSuccess: (res) => {
        setState({ idCard: res.originalImageUrl, idCardUrl: res.presignedUrl, loading: false })
        console.log(res)
        changeAnswer(ques, e)
      },
      onFailure: () => {
        alert("Photo not uploaded.pls try again")
        setState({ loading: false })
      }
    });
    setState({ localImageUrl: photo })
  };

  const onDeletePhoto = (objectId, question, photoIndex) => {
    console.log("onDeletePhoto", objectId)
    let isDelete = window.confirm("Are you sure you want to delete? \nNote: Deleting image will update the audit automatically")
    console.log(isDelete, "isDelete")
    var audit = structuredClone(state.editedAudits.audits);
    if (isDelete) {
      audit.map((q) => {
        if (q.questionId == question.questionId) {
          q.photos.splice(photoIndex, 1)
          q.localPic.splice(photoIndex, 1)
        }
      });
      deleteImages(accessToken, dispatch, objectId,
        {
          onSuccess: () => { },
          onFailure: () => { }
        })
      console.log(audit)
      setState({ editedQues: audit, editedAudits: { ...state.editedAudits, audits: audit } })
      setTimeout(() => {
        save(true)
      }, 1000)
    }
  }

  const onSelectRotatePhoto = (objectId, question, photoIndex, quesIndex) => {
    let rotateData = state.rotateData
    rotateData.objectId = objectId
    rotateData.questionId = question.questionId
    rotateData.photoIndex = photoIndex
    rotateData.quesIndex = quesIndex
    rotateData.presignedUrl = question?.localPic?.[photoIndex]?.['uri'] ?? ""
    setState({ rotateModal: true, rotateData: rotateData })
  }

  const onPressRotatePhoto = (rotatedirection) => {
    let rotateData = state.rotateData
    setState({ loading: true })
    changeRotationImage(
      accessToken,
      dispatch,
      rotateData.masterDataId,
      rotateData.questionId,
      rotateData.objectId,
      rotatedirection,
      {
        onSuccess: (resData) => {
          if (resData.success) {
            rotateData.objectData = resData.data
            rotateData.presignedUrl = resData.presignedUrl
            setState({ rotateData: rotateData, loading: false })
          }
          else {
            setState({ loading: false })
            alert(resData.message??"Unable to Rotate")
          }
          // console.log(data);
        },
        onFailure: (errorData) => {
          console.log(errorData)
          setState({ loading: false })
        }
      })
  }

  const onSaveRotatePhoto = () => {
    let rotateData = state.rotateData
    var audit = structuredClone(state.editedAudits.audits);
    setState({ loading: true })
    saveRotationImage(
      accessToken,
      dispatch,
      rotateData.masterDataId,
      rotateData.objectData,
      {
        onSuccess: (resData) => {
          rotateData.objectId = resData.originalImageUrl
          rotateData.presignedUrl = resData.presignedUrl
          setState({ rotateData: rotateData, loading: false })
          audit[rotateData.quesIndex]['localPic'][rotateData.photoIndex]['uri'] = resData.presignedUrl
          setState({ editedQues: audit, editedAudits: { ...state.editedAudits, audits: audit } })
          closeimage()
          // console.log(data);
        },
        onFailure: (errorData) => {
          console.log(errorData)
          setState({ loading: false })
        }
      })
  }

  var questions = [];
  if (user.questions != undefined) {
    questions = user.questions;
  }

  // console.log(state.editedAudits.audits, 'audits')
  return (
    <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <div
        style={{
          display: "flex",
          // flex: 1,
          justifyContent: "space-between",
          flexDirection: "row",
          margin: 20,
        }}
      >
        <h4
          style={{
            marginLeft: 20,
            // marginBottom: 35,
            color: "darkgoldenrod",
            display: "inline-block",
            fontSize: 30,
            fontFamily: "sans-serif",
            fontWeight: "bold",
          }}
        >
          {props.storeName}
        </h4>
        <Button
          icon="left arrow"
          label="GO BACK"
          onClick={props.onClose}
          style={{ width: '150px', height: '40px' }}
          color="black"
        >
          GO BACK
        </Button>
        {/* <Icon
            name="arrow"
            className="left large"
            color="brown"
            style={{ cursor: "pointer" }}
            onClick={props.onClose}
          /> */}
      </div>
      {((isAdminUser == false && props?.auditsType == "Residence") || (state.isRejectedCase)) ? <></> :
        <>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              flex: 1,
              margin: 10,
              marginRight: 25
            }}
          >
            {!state.EditView && (
              <Button style={{ width: '150px' }} onClick={open} color="facebook">
                Edit
              </Button>
            )}
            {state.EditView && (
              <div>
                <Button style={{ width: '150px' }} onClick={save} color="green">
                  Save
                </Button>
                <Button style={{ width: '150px' }} onClick={cancel} color="red">
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </>}
      <Modal
        open={state.imageModal}
        closeIcon
        onClose={closeimage}
        style={{ marginTop: '30px' }}
        size="small"
      >
        <Modal.Content image>
          <Image size='huge' src={state.zoomimage} wrapped />
        </Modal.Content>
        {/* <Modal.Actions >
          <Button color="red" onClick={closeimage}>
            <Icon name="remove" /> Close
          </Button>
        </Modal.Actions> */}
      </Modal>
      <Modal
        open={state.rotateModal}
        size="small"
      >
        <Modal.Content image>
          <Image size='huge' src={state.rotateData.presignedUrl} wrapped />
        </Modal.Content>
        <Modal.Actions >
          <Button color="blue" onClick={() => { onPressRotatePhoto('left') }}>
            <Icon name="undo alternate" /> Left
          </Button>
          <Button color="blue" onClick={() => { onPressRotatePhoto('flip') }}>
            <Icon name="sync alternate" /> Upside Down
          </Button>
          <Button color="blue" onClick={() => { onPressRotatePhoto('right') }}>
            <Icon name="redo alternate" /> Right
          </Button>
          <Button color="green" onClick={onSaveRotatePhoto}>
            <Icon name="save" /> Save
          </Button>
          <Button color="red" onClick={closeimage}>
            <Icon name="remove" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
      <div style={{ display: "flex", flex: 1, justifyContent: "center" }}>
        <Segment style={{ width: "95vw" }}>
          {/* {console.log(state.editedAudits, 'edited')} */}
          {state.editedAudits != null &&
            state.editedAudits.audits != undefined &&
            state.editedAudits.audits.length > 0 &&
            state.editedAudits.audits.map((ques, quesIndex) => (
              <Grid key={quesIndex}>
                {ques.answerType === "date" && (
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <span
                        style={{
                          fontWeight: "bold",
                          fontFamily: "sans-serif",
                          fontSize: '16px'

                        }}
                      >
                        {ques.question}
                      </span>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          value={ques.answer ? new Date(moment(ques.answer, 'DD-MM-YYYY')) : null}
                          onChange={(date) => changeAnswer(ques, date)}
                          format="dd/MM/yyyy"
                          disabled={!state.disabled || !isAdminUser}
                          placeholder="From-Date"
                        />
                      </MuiPickersUtilsProvider>
                    </Grid.Column>
                  </Grid.Row>
                )}
                {ques.answerType === "text" && (ques.question != 'Remarks') && (
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {ques.question}
                      </span>
                    </Grid.Column>
                    <Grid.Column width={8}>
                      {" "}
                      <Input
                        value={ques.answer}
                        disabled={!state.disabled || !isAdminUser}
                        onChange={(e) => changeAnswer(ques, e)}
                        style={{ width: "100%" }}
                      />{" "}
                    </Grid.Column>
                  </Grid.Row>
                )}
                {ques.answerType === "text" && (ques.question == 'Remarks') && (
                  <Grid.Row>
                    <Grid.Column width={8}>
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {ques.question}
                      </span>
                    </Grid.Column>
                    <Grid.Column width={8} >
                      {" "}
                      <TextArea
                        value={ques.answer}
                        disabled={!state.disabled || (!isAdminUser && remarksAccess == false)}
                        onChange={(e) => changeAnswer(ques, e)}
                        placeholder="Case Remarks"
                        style={{ width: "100%", height: '100px', borderColor: 'lightgrey', borderWidth: 3, borderRadius: 3, }}
                      />{" "}
                    </Grid.Column>
                  </Grid.Row>
                )}
                {ques.answerType === "options" && (
                  <Grid.Row>
                    <Grid.Column width={8}>
                      {" "}
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {ques.question}
                      </span>{" "}
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Dropdown
                        options={ques.options.map((label, i) => {
                          return {
                            value: label.value,
                            text: label.label,
                            key: label.value,
                          };
                        })}
                        onChange={(e, { value }) =>
                          changeAnswer(ques, value)
                        }
                        style={{ width: "100%" }}
                        disabled={!state.disabled || !isAdminUser}
                        value={ques.answer}
                        selection
                        placeholder={"Select any option"}
                      />{" "}
                    </Grid.Column>
                  </Grid.Row>
                )}
                {ques.answerType === "photo" && (
                  <Grid.Row style={{ borderWidth: 2, borderRadius: 10, borderStyle: 'solid', marginLeft: '10px', marginRight: '10px', marginTop: 10, borderColor: 'lightgrey' }}>
                    <Grid.Column width={8}>
                      {" "}
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {ques.question}
                      </span>
                      {/* {state.disabled === true && isAdminUser && (
                        <div style={{ display: 'flex', flexDirection: 'row', width: '70%', padding: '15px', borderRadius: 5, marginTop: 10, borderStyle: 'dashed', borderWidth: 2, borderColor: 'lightgray' }}>
                          <div style={{ width: '350px' }}>
                            <input
                              type="file"
                              content="Upload additional Photos"
                              icon="cloud upload"
                              labelPosition="right"
                              onChange={(e) => { onPhotoSelect(ques, e) }}
                              multiple
                              style={{ display: "inline-block" }}
                              accept=".jpg,.png,.jpeg"
                            />
                          </div>
                          <div style={{ width: '200px' }}>
                            <Button
                              style={{ width: "100%" }}
                              color="green"
                              size="small"
                              loading={state.loading}
                              onClick={(e) => { changeAnswer(ques, e) }}
                            >
                              Upload
                            </Button>
                          </div>

                        </div>
                      )} */}
                    </Grid.Column>
                    <Grid.Column width={6}>
                      <div style={{ display: 'grid', padding: "10px", gridTemplateColumns: "auto auto auto" }}>
                        {ques.localPic &&
                          ques.localPic.length > 0 &&
                          ques.localPic.map((e, i) => {
                            return (
                              <div>
                                <Image
                                  size="small"
                                  style={{ cursor: "pointer" }}
                                  src={e.uri ?? ""}
                                  onClick={() => zoom(e?.uri ?? "")}
                                  fluid
                                ></Image>
                                {!(!state.disabled || !isAdminUser) &&
                                  <div onClick={() => { onDeletePhoto(ques?.photos?.[i] ?? "", ques, i) }} style={{ cursor: 'pointer', borderWidth: 2, marginTop: 5, width: '125px', borderStyle: 'dotted', borderColor: 'red', padding: 2, borderRadius: 5, }}>
                                    <Icon name="trash" disabled={!state.disabled || !isAdminUser} color="red" size="large"></Icon>
                                    Delete photo
                                  </div>
                                }
                                {!(!state.disabled || (!isAdminUser && rotateAccess == false)) &&
                                  <div onClick={() => { onSelectRotatePhoto(ques?.photos?.[i] ?? "", ques, i, quesIndex) }} style={{ cursor: 'pointer', borderWidth: 2, marginTop: 5, width: '125px', borderStyle: 'dotted', borderColor: 'blue', padding: 2, borderRadius: 5, }}>
                                    <Icon name="history" disabled={!state.disabled || !isAdminUser} color="blue" size="large"></Icon>
                                    Rotate photo
                                  </div>
                                }
                                <br></br>
                              </div>
                            );
                          })}{" "}
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                )}
                {ques.answerType === "signPad" && (
                  <Grid.Row>
                    <Grid.Column width={8}>
                      {" "}
                      <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                        {ques.question}
                      </span>{" "}
                    </Grid.Column>
                    <Grid.Column width={8}>
                      <Image.Group
                        size="medium"
                        style={{ marginBottom: "10px" }}
                      >
                        <Image
                          // size="large"
                          onClick={() => zoom(ques?.answerUrl ?? "")}
                          src={ques?.answerUrl ?? ""}
                        />
                      </Image.Group>{" "}
                    </Grid.Column>
                  </Grid.Row>
                )}

              </Grid>
            ))}
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                {" "}
                <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                  {'Proximity Distance'}
                </span>{" "}
              </Grid.Column>
              <Grid.Column width={8}>
                {" "}
                <span style={{ fontSize: "16px" }}>
                  {state.editedAudits.distance}
                </span>{" "}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={8}>
                {" "}
                <span style={{ fontWeight: "bold", fontSize: "16px" }}>
                  {'Proximity Color'}
                </span>{" "}
              </Grid.Column>
              <Grid.Column width={8}>
                {" "}
                <span style={{ fontSize: "16px" }}>
                  {state.editedAudits.distance_color}
                </span>{" "}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
    </div>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     auth: state.auth,
//     user: state.user,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators(
//     {
//       fetchQuestions: fetchQuestions,
//       submitAudits: submitAudits,
//       UploadImages
//     },
//     dispatch
//   );
// };

// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(Questions)
// );

export default Questions
