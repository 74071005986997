import {
  LOGIN_SUCCESS_ACTION,
  SELECT_MENU_ACTION,
  LOGIN_FAILURE_ACTION,
  LOGOUT_SUCCESS_ACTION,
  LOGOUT_FAILURE_ACTION,
  CONTROL_PANEL_SUCCESS_ACTION,
  CONTROL_PANEL_FAILURE_ACTION,
  ADD_USER_SUCCESS_ACTION,
  ADD_USER_FAILURE_ACTION,
  UPLOAD_ID_CARD_SUCCESS_ACTION,
  UPLOAD_ID_CARD_FAILURE_ACTION,
  GET_JURISDICTION_SUCCESS_ACTION,
  GET_JURISDICTION_FAILURE_ACTION,
  EDIT_USER_SUCCESS_ACTION,
  EDIT_USER_FAILURE_ACTION,
  DELETE_USER_SUCCESS_ACTION,
  DELETE_USER_FAILURE_ACTION,
  UPLOAD_MASTER_CSV_SUCCESS,
  UPLOAD_CITY_MASTER_CSV_SUCCESS,
  UPLOAD_PINCODE_MASTER_CSV_SUCCESS,
  UPLOAD_RESIDENCE_MASTER_CSV_SUCCESS,
  INITIATE_UPLOAD,
  DISMISS_LOG,
} from "../actions/types";
import moment from "moment";

const INIT_STATE = {
  uploadIncomplete: false,
  isLoggedIn: false,
  accessToken: "",
  remarksAccess: false,
  rotateAccess: false,
  theme: 'light',//dark , light
};

export const AuthReducer = (state = INIT_STATE, action) => {
  // state = Object.assign({}, state, {});
  switch (action.type) {
    case LOGIN_SUCCESS_ACTION:
      console.log("ON LOGIN SUCCESS", action)
      return JSON.parse(JSON.stringify({
        ...state,
        email: action.payload.Email,
        loginId: action.payload.loginId,
        Name: action.payload.Name,
        accessToken: action.payload.access_token,
        role: action.payload.Role,
        expiresIn: moment().add(action.payload.expires_in, "seconds").format(),
        loginError: false,
        message: action.payload.message,
        remarksAccess: action?.payload?.remarks_access ?? false,
        rotateAccess: action?.payload?.rotate_access ?? false,
        isLoggedIn: true
      }));

    case LOGIN_FAILURE_ACTION:
      return {
        loginError: true,
        isLoggedIn: false
      };

    case LOGOUT_SUCCESS_ACTION:
      return {
        logoutError: false,
        accessToken: "",
        isLoggedIn: false
      };

    case LOGOUT_FAILURE_ACTION:
      return {
        ...state,
        logoutError: true,
        accessToken: "",
        isLoggedIn: false
      }

    case CONTROL_PANEL_SUCCESS_ACTION:
      return JSON.parse(JSON.stringify({
        ...state,
        allusers: action.payload.Users,
        userError: false,
        jurisdiction: action.payload.jurisdiction,
        teamLeader: action.payload.teamLeader,
        teamMember: action.payload.teamMember,
        reportTL: action.payload.under_me.teamLeader,
        reportTM: action.payload.under_me.teamMember,
        reportFE: action.payload.under_me.fieldExecutive,
        userUnderMe: [...action.payload.under_me.teamLeader, ...action.payload.under_me.teamMember, ...action.payload.under_me.fieldExecutive],
        assignFE: action.payload.fieldExecutive,

      }))

    case CONTROL_PANEL_FAILURE_ACTION:
      return {
        ...state,
        userError: true
      }

    case ADD_USER_SUCCESS_ACTION:
      return {
        ...state,
        idcard: "",
        adduser: action.payload,
        addError: false,
      }
    case ADD_USER_FAILURE_ACTION:
      return {
        ...state,
        addError: true
      }
    case EDIT_USER_SUCCESS_ACTION:
      return {
        ...state,
        edituser: action.payload,
        editError: false,
      }
    case EDIT_USER_FAILURE_ACTION:
      return {
        ...state,
        editError: true
      }
    case DELETE_USER_SUCCESS_ACTION:
      return {
        ...state,
        deleteuser: action.payload,
        deleteError: false,
      }
    case DELETE_USER_FAILURE_ACTION:
      return {
        ...state,
        deleteError: true,
      }
    case UPLOAD_ID_CARD_SUCCESS_ACTION:
      return {
        ...state,
        idcard: action?.payload ?? "",
        uploadError: false,
      }
    case UPLOAD_ID_CARD_FAILURE_ACTION:
      return {
        ...state,
        uploadError: true,
      }
    case GET_JURISDICTION_SUCCESS_ACTION:
      return {
        ...state,
        jurisdictions: action.payload,
        jurisError: false,
      }
    case GET_JURISDICTION_FAILURE_ACTION:
      return {
        ...state,
        jurisError: true,
      }
    case SELECT_MENU_ACTION:
      return Object.assign({}, state, {
        selectedMenu: action.payload,
      });

    case UPLOAD_MASTER_CSV_SUCCESS:
    case UPLOAD_CITY_MASTER_CSV_SUCCESS:
    case UPLOAD_PINCODE_MASTER_CSV_SUCCESS:
    case UPLOAD_RESIDENCE_MASTER_CSV_SUCCESS:
      return {
        ...state,
        uploadIncomplete: false,
        log: action?.payload ?? "",
        showLog: true
      }

    case INITIATE_UPLOAD:
      return {
        ...state,
        uploadIncomplete: action.payload,
        showLog: false
      }
    case DISMISS_LOG:
      return {
        ...state,
        showLog: action.payload
      }
    default:
      return state
  }
  // if (state.accessToken) {
  //   setupAxios(state.accessToken);
  // }

  // return structuredClone(state);;
}
