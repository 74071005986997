import React, { useEffect, useState } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, withRouter } from "react-router";
import { isLoggedIn } from "./../../util";
import { Container, Segment, Input, Icon, Label, Modal, Grid, Form, Dimmer, Loader, Checkbox, } from "semantic-ui-react";
import { logoutAction, fetchUseraction, adduser, fetchId, fetchJurisdiction, EditUser, DeleteUser, } from "../../actions/index";
import { userExport, cityMasterExport, ClearUserSession } from "../../actions/users_action";
import { Sidebar, Menu, Header, Image, Button, Dropdown } from "semantic-ui-react";
import { STORAGE_URL } from "../../actions/types";

function Users(props) {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector((rdx) => rdx.auth)
  const accessToken = auth.accessToken
  const user = useSelector((rdx) => rdx.user)
  const islogin = auth.isLoggedIn
  const [state, setStateValue] = useState({
    Editmode: false,
    card: "",
    name: "",
    email: "",
    phonenumber: "",
    pincode: "",
    selectedPhoto: "",
    photoId: "",
    field: "",
    jurist: [],
    juris: "",
    userId: "",
    loginId: "",
    editedData: false,
    disable: false,
    idCard: "",
    idCardUrl: "",
    userPhoto: "",
    userPhotoUrl: "",
    teamLeader: [],
    tm: "",
    teamMember: "",
    tmId: "",
    tlId: "",
    loading: false,
    user_id: null,
    remarksAccess: false,
    rotateAccess: false,
    loaName: "",
    loaAddress: ""
  })
  const setState = (value = {}) => {
    setStateValue((old) => {
      return { ...old, ...value }
    })
  }
  const mountFunction = async () => {
    await fetchJurisdiction(accessToken, dispatch);
    await fetchUsers()
  }
  const fetchUsers = async () => {
    await fetchUseraction(accessToken, dispatch,
      {
        onSuccess: () => { setState({ loading: false }) },
        onFailure: () => { setState({ loading: false }) }
      });
  }
  useEffect(() => {
    mountFunction()
    setState({ loading: true })
  }, [])
  useEffect(() => {
    if (auth.isLoggedIn == false) {
      navigation(`/login`);
    }
  }, [auth.isLoggedIn])

  const Namechange = (e) => {
    setState({
      name: e.target.value,
    });
  };
  const Loginchange = (e) => {
    setState({
      loginId: e.target.value,
    });
  };

  const Emailchange = (e) => {
    setState({
      email: e.target.value,
    });
  };
  const Numberchange = (e) => {
    setState({
      phonenumber: e.target.value,
    });
  };
  const Pinchange = (e) => {
    setState({
      pincode: e.target.value,
    });
  };
  const LoaNameChange = (e) => {
    setState({
      loaName: e.target.value,
    });
  };
  const LoaAddressChange = (e) => {
    setState({
      loaAddress: e.target.value,
    });
  };
  const addNewUser = () => {
    console.log('addNewUser')
    setState({
      Editmode: true,
      editedData: false,
      name: "",
      email: "",
      loginId: "",
      phonenumber: "",
      idCard: "",
      idCardUrl: "",
      userPhoto: "",
      userPhotoUrl: "",
      remarksAccess: false,
      rotateAccess: false,
      loaName: "",
      loaAddress: "",
      tlId: "",
      tmId: "",
      // pincode: "",
      role: "",
      juris: "",
    });
  };
  const logout = () => {
    let email = user.email;
    logoutAction(accessToken, dispatch, email);
    setTimeout(() => {
      navigation("/login", { replace: true });
    }, 1000);
  };
  const selectField = (e, Combo) => {
    if (Combo.value === "Field Executive") {
      setState({
        disable: true,
      });
    } else {
      setState({
        disable: false,
      });
    }
    setState({
      role: Combo.value,
    });
  };
  const selectjuris = (e, Combo) => {
    // console.log("Select Juris", Combo)
    let tl = []
    auth.teamLeader.map((d) => {
      if (d.jurisdiction === Combo.value) {
        tl.push({
          key: d.id,
          text: d.name,
          value: d.name
        })
      }

    })
    if (tl.length > 0) {
      setState({ tl: tl[0].text, tlId: tl[0].key })
    }
    // console.log("Tl", tl)
    setState({
      juris: Combo.value,
      teamLeader: tl
    });
    // try {
    if (auth.teamMember != undefined) {
      let teamMemberList = auth.teamMember
      let teamMembers = teamMemberList.filter((e) => {
        if (e.teamLeaderId === tl[0].key) {
          return e
        }
      });
      if (teamMembers.length > 0) {
        setTimeout(() => {
          setState({
            tmId: teamMembers[0].id,
            teamMember: teamMembers[0].name
          })
        }, 1000)

        // console.log("Teammembers", teamMembers[0])
      }
      else {
        setState({
          tmId: "",
          teamMember: ""
        })
      }
    }
    // } catch (error) {

    // }
  };

  const selecttm = (e, Combo) => {
    let tmId = ''
    Combo.options.map((d) => {
      if (d.value === Combo.value) {
        tmId = d.key
      }
    })
    setState({
      teamMember: Combo.value,
      tmId: tmId
    })
  }

  const onPhotoSelect = (e) => {
    setState({
      selectedPhoto: e.target.files[0],
    });
  };
  const uploadPhoto = (e, type) => {
    setState({
      photoId: e._id,
      loading: true
    });
    let photo = state.selectedPhoto;
    fetchId(accessToken, dispatch, photo, {
      onSuccess: (res) => {
        // console.log(res)
        if (type == 'userPhoto')
          setState({ userPhoto: res.originalImageUrl, userPhotoUrl: res.presignedUrl, loading: false })
        else
          setState({ idCard: res.originalImageUrl, idCardUrl: res.presignedUrl, loading: false })
        // alert("Photo uploaded Successfully")
      },
      onFailure: () => {
        // alert("Photo not uploaded")
        setState({ loading: false })
      }
    });
  };
  const closeModal = () => {
    setState({
      Editmode: false,
    });
  };
  const editUserDetail = (data) => {
    // console.log("Edit Data", data)
    setState({
      user_id: data._id,
      Editmode: true,
      editedData: true,
      name: data.displayName,
      email: data.email,
      loginId: data.loginId,
      phonenumber: data.mobileNumber,
      tlId: data.teamLeaderId,
      tmId: data.teamMemberId,
      teamLeader: [{ value: data.teamLeaderId }],
      // teamMember: data.teamMemberId,
      // pincode: data.pincode,
      role: data.role,
      juris: data.jurisdiction,
      idCard: data.idCard,
      idCardUrl: data.idCardUrl,
      userPhoto: data?.photo ?? "",
      userPhotoUrl: data?.photoUrl ?? "",
      remarksAccess: data?.remarksAccess ?? false,
      rotateAccess: data?.rotateAccess ?? false,
      loaName: data?.loaName ?? "",
      loaAddress: data?.loaAddress ?? ""
    });
    // this.selectjuris("e", { value: data.jurisdiction })
    setTeamMember(data.teamMemberId)
    setTeamLeader(data.teamLeaderId)
  };

  const setTeamMember = (tmId) => {
    var teamMember = ""
    if (auth.teamMember != undefined) {
      // console.log("Set Team Member", this.props.auth.teamMember[0])
      auth.teamMember.map((e) => {
        if (e.id === tmId) {
          // teamMember.push({ text: e.name, value: e.name, key: e.id });
          teamMember = e.name
        }

      });
      // console.log(teamMember, tmId)
      setState({
        teamMember: teamMember,
        tmId: tmId
      })
    }
  }
  const setTeamLeader = (tlId) => {
    var teamLeader = []
    if (auth.teamLeader != undefined) {
      // console.log("Set Team Leader", this.props.auth.teamLeader[0])
      auth.teamLeader.map((e) => {
        if (e.id === tlId) {
          teamLeader.push({ text: e.name, value: e.name, key: e.id });
          // teamLeader= e.name
        }

      });
      // console.log(teamLeader, tlId)
      setState({
        teamLeader: teamLeader,
        tlId: tlId
      })
    }
  }

  const deleteUser = async (data) => {
    let isConfirm = await window.confirm(`Are you sure you want to delete User? \n Name : ${data?.displayName ?? "Name"} \n Id : ${data?.loginId ?? "id"}`)
    if (isConfirm) {
      onDeletUser()
    }

    function onDeletUser() {
      DeleteUser(accessToken, dispatch, data._id, {
        onSuccess: () => {
          setState({ loading: true })
          fetchUsers()
        },
        onFailure: () => { },
      });

    }
  };



  const clearSession = (data) => {
    var session = {
      loginId: data.loginId
    }
    ClearUserSession(accessToken, dispatch, session, {
      onSuccess: () => {
        setState({ loading: true })
        fetchUsers()
        alert("Session Cleared Successfully")
      },
      onFailure: () => {

      }
    })
  };

  const edituser = () => {
    setState({ loading: true })
    let displayName = state.name;
    let email = state.email;
    let loginId = state.loginId;
    let mobileNumber = state.phonenumber;
    let role = state.role;
    // let idcard = role == "Field Executive" ? this.props.auth.idcard.originalImageUrl : '';
    let idcard = state.idCard;
    let userPhoto = state.userPhoto;
    let jurisdiction = state.juris;
    let _id = state.user_id;
    let teamLeaderId = state.tlId
    let teamMemberId = state.tmId
    let remarksAccess = state.remarksAccess
    let rotateAccess = state.rotateAccess
    let loaName = state.loaName
    let loaAddress = state.loaAddress
    EditUser(accessToken, dispatch,
      displayName,
      email,
      loginId,
      mobileNumber,
      idcard,
      userPhoto,
      remarksAccess,
      rotateAccess,
      loaName,
      loaAddress,
      role,
      jurisdiction,
      _id,
      {
        onSuccess: (res) => {
          setState({ loading: false })
          if (res.status == 200) {
            alert(res.data.message !== undefined ? res.data.message : 'Failed to Update User')
            // alert('User Updated Succesfully')
            setState({ loading: true, Editmode: false })
            fetchUsers()
          }
        },
        onFailure: () => {
          setState({ loading: false })
          alert("User Not Updated")
        },
      },
      teamLeaderId,
      teamMemberId
    );
  };
  const saveNewUser = () => {
    let displayName = state.name;
    let email = state.email;
    let loginId = state.loginId;
    let mobileNumber = state.phonenumber;

    let role = state.role;
    let idcard = role == "Field Executive" ? auth.idcard.originalImageUrl : '';
    let photo = state.userPhoto;
    let jurisdiction = state.juris;
    let teamLeaderId = state.tlId;
    let teamMemberId = state.tmId;
    let remarksAccess = state.remarksAccess
    let rotateAccess = state.rotateAccess
    let loaName = state.loaName
    let loaAddress = state.loaAddress

    if (photo == "" && role == "Field Executive") {
      alert("Please Upload Photo.User Id card is Manadatory");
    } else {
      setState({ loading: true })
      adduser(accessToken, dispatch,
        displayName,
        email,
        loginId,
        mobileNumber,
        idcard,
        photo,
        remarksAccess,
        rotateAccess,
        loaName,
        loaAddress,
        role,
        jurisdiction,
        teamLeaderId,
        teamMemberId,
        {
          onSuccess: (res) => {
            setState({ loading: false })
            if (res.status == 200) {
              if (res.data.success == true) {
                setState({ Editmode: false, loading: true });
                fetchUsers()
              }
            }
          },
          onFailure: () => {
            setState({ loading: false })
            alert("Something went wrong")
            // this.setState({ Editmode: false });
          },
        }
      );
    }
  };
  const home = () => {
    navigation("/home");
  };

  const userExportfn = () => {
    userExport(accessToken, dispatch);
  };

  const cityMasterExportfn = () => {
    cityMasterExport(accessToken, dispatch);
  };

  const decryptPassword = (pwd) => {
    var key = 10

    var decryted_text = ''
    for (let i = 0; i < pwd.length; i++) {
      decryted_text += String.fromCharCode(pwd[i].charCodeAt(0) - key)
    }
    return decryted_text
  }


  let jurist = [];
  if (auth.jurisdiction != undefined) {
    auth.jurisdiction.map((e) => {
      jurist.push({ text: e.name, value: e.name });
    });
  }
  let teamMember = [];
  if (auth.teamMember != undefined) {
    auth.teamMember.map((e) => {
      if (e.teamLeaderId === state.tlId) {
        teamMember.push({ text: e.name, value: e.name, key: e.id });
      }

    });
  }
  var data = auth.allusers;

  var options = [
    {
      key: "Team Leader",
      text: "Team Leader",
      value: "Team Leader",
    },
    {
      key: "Team Member",
      text: "Team Member",
      value: "Team Member",
    },

    {
      key: "Field Executive",
      text: "Field Executive",
      value: "Field Executive",
    },
  ];
  var columns = [
    {
      Header: "Name",
      accessor: "displayName",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Jurisdiction",
      accessor: "jurisdiction",
    },
    // {
    //   Header: "Pincode",
    //   accessor: "pincode",
    // },
    {
      Header: "Role",
      accessor: "role",
    },
    {
      Header: "Login Id",
      accessor: "loginId",
    },
    // {
    //   Header: "Password",
    //   accessor: "password",
    //   Cell: (row) => (
    //     <AuditTableCell
    //       row={row.original}
    //       text={this.decryptPassword(row.original.password)}
    //     />
    //   ),
    // },
    {
      Header: "Edit",
      accessor: "editing",
      Cell: (row) => (
        <div>
          {!state.Editmode && (
            <Icon
              name="edit"
              onClick={() => editUserDetail(row.original)}
              style={{
                width: 100,
                align: "center",
                size: "huge",
                marginLeft: "25%",
                cursor: "pointer",
                color: "green",
              }}
            />
          )}
        </div>
      ),
    },
    {
      Header: "Delete",
      accessor: "delete",
      Cell: (row) => (
        <div>
          <Icon
            name="delete"
            onClick={() => deleteUser(row.original)}
            style={{
              width: 100,
              align: "center",
              size: "huge",
              marginLeft: "25%",
              cursor: "pointer",
              color: "red",
            }}
          ></Icon>
        </div>
      ),
    },
    {
      Header: "Clear Session",
      accessor: "clear",
      Cell: (row) => (
        <div>
          <Icon
            name="power off"
            onClick={() => clearSession(row.original)}
            style={{
              width: 100,
              align: "center",
              size: "huge",
              marginLeft: "25%",
              cursor: "pointer",
              color: row.original.status == "Active" ? "green" : "red"
            }}
          ></Icon>
        </div>
      ),
    },
  ];
  return (
    <div style={{ height: "100%", backgroundColor: "white" }}>
      <Dimmer active={state.loading}>
        <Loader>Loading</Loader>
      </Dimmer>
      <div style={{ width: "200%" }}>
        <h1
          style={{
            paddingLeft: 30,
            flex: "0 0 30px",
            display: "inline-block",
            color: "teal",
          }}
        >
          User Details
        </h1>
        <br />
        <Label
          style={{ marginLeft: 30, marginBottom: 10, cursor: "pointer" }}
          size="large"
          color="blue"
          onClick={addNewUser}
        >
          <Icon name="user plus" />
          AddUsers
        </Label>
        <Label
          style={{ marginLeft: 30, marginBottom: 10, cursor: "pointer" }}
          size="large"
          color="blue"
          onClick={userExportfn}
        >
          <Icon name="download" />
          User Export
        </Label>
        {/* <Label
            style={{ marginLeft: 30, marginBottom: 10, cursor: "pointer" }}
            size="large"
            color="blue"
            onClick={cityMasterExportfn}
          >
            <Icon name="download" />
            CityMaster Export
          </Label> */}

        <div style={{ display: "flex", overflowX: "scroll" }}>
          <ReactTable
            noDataText="We couldn't find anything"
            filterable={true}
            defaultPageSize={20}
            sortable={true}
            style={{ height: "85%", width: "93dvw", marginLeft: 30 }}
            columns={columns}
            data={data}
          />
        </div>

        <Modal
          open={state.Editmode}
          onClose={closeModal}
          size="small"
        >
          <Modal.Content>
            <Form>
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Name"
                      value={state.name}
                      onChange={(e) => Namechange(e)}
                      placeholder="your Name"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Email"
                      value={state.email}
                      onChange={(e) => Emailchange(e)}
                      placeholder="your Email"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="Login ID"
                      disabled={state.editedData}
                      value={state.loginId}
                      onChange={(e) => Loginchange(e)}
                      placeholder="login Id"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="any"
                      label="Phone Number"
                      value={state.phonenumber}
                      onChange={(e) => Numberchange(e)}
                      placeholder="your phonenumber"
                    />
                  </Grid.Column>
                  {/* <Grid.Column style={{ marginTop: 20 }}>
                      <Form.Input
                        type="any"
                        label="PinCode"
                        value={this.state.pincode}
                        onChange={(e) => this.Pinchange(e)}
                        placeholder="Enter pincodes with ',' "
                      />
                    </Grid.Column> */}
                  {state.role != 'Admin' &&
                    <Grid.Column style={{ marginTop: 20 }}>
                      <h4>Select Role</h4>
                      <Dropdown
                        fluid
                        selection
                        search
                        placeholder="Select Fields"
                        options={options}
                        defaultValue={state.role}
                        onChange={selectField}
                      />
                    </Grid.Column>
                  }
                  <Grid.Column style={{ marginTop: 20 }}>
                    <h4>Select Jurisdiction</h4>
                    <Dropdown
                      fluid
                      selection
                      search
                      options={jurist}
                      defaultValue={state.juris}
                      placeholder="Select Jurisdiction"
                      onChange={selectjuris}
                    />
                  </Grid.Column>
                  {/* <Grid.Column style={{ marginTop: 20 }}></Grid.Column> */}
                  {state.role === 'Team Member' && (
                    <Grid.Column style={{ marginTop: 20 }}>
                      <h4>Team Leader</h4>
                      <Form.Input
                        type="text"
                        value={state.teamLeader.length > 0 ? state.teamLeader[0]?.value ?? "" : ""}
                        disabled={false}
                        editable={false}
                        placeholder="your Email"
                      />
                      {/* <Dropdown
                          fluid
                          selection
                          search
                          options={this.state.teamLeader}
                          defaultValue={this.state.teamLeader}
                          placeholder="Select Team leader"
                          onChange={() => { }}
                        /> */}
                    </Grid.Column>
                  )}
                  {state.role === 'Field Executive' && (
                    <div>
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <Grid.Column style={{ marginTop: 20, width: '100%', marginLeft: 20 }}>
                          <h4>Team Leader</h4>
                          <Form.Input
                            type="text"
                            value={state.teamLeader.length > 0 ? state.teamLeader[0]?.value ?? "" : ""}
                            disabled={false}
                            editable={false}
                            placeholder="your Email"
                          />
                          {/* <Dropdown
                              fluid
                              selection
                              search
                              options={this.state.teamLeader}
                              defaultValue={this.state.teamLeader}
                              placeholder="Select Team leader"
                              onChange={() => { }}
                            /> */}
                        </Grid.Column>
                        <Grid.Column style={{ marginTop: 20, width: '100%', marginLeft: 150 }}>
                          <h4>Team Member</h4>
                          <Dropdown
                            fluid
                            selection
                            search
                            options={teamMember}
                            defaultValue={state.teamMember}
                            placeholder="Select Team Member"
                            onChange={selecttm}
                          />
                        </Grid.Column>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>

                        <Grid.Column style={{ marginTop: 20 }}>
                          <h4>User Id Card</h4>
                          <input
                            type="file"
                            content="Upload additional Photos"
                            icon="cloud upload"
                            labelPosition="right"
                            onChange={(e) => onPhotoSelect(e)}
                            multiple
                            style={{ display: "inline-block" }}
                            accept=".jpg,.png,.jpeg"
                          />
                        </Grid.Column>
                        <Grid.Column style={{ marginLeft: 20 }}>
                          <Button
                            style={{ marginTop: 60, marginRight: "70%" }}
                            color="green"
                            size="small"
                            loading={state.loading}
                            onClick={(e) => uploadPhoto(e)}
                          >
                            Upload
                          </Button>
                        </Grid.Column>

                        <Grid.Column style={{ marginTop: 20 }}>
                          {state.idCard != "" &&
                            state.idCard != undefined && (
                              <Image
                                size="small"
                                style={{ cursor: "pointer" }}
                                src={state.idCardUrl}
                                // src={this.state.idCard!=""?(STORAGE_URL+ this.state.idCard):this.state.idCard}
                                // src={"https://storage.matex.co.in/minio/address-verification/ID-CARD/179b839b-5bb3-4fbd-9b25-60c25c4bf6da.jpeg"}
                                fluid
                              ></Image>
                            )}
                        </Grid.Column>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>

                        <Grid.Column style={{ marginTop: 20 }}>
                          <h4>User Photo</h4>
                          <input
                            type="file"
                            content="Upload additional Photos"
                            icon="cloud upload"
                            labelPosition="right"
                            onChange={(e) => onPhotoSelect(e)}
                            multiple
                            style={{ display: "inline-block" }}
                            accept=".jpg,.png,.jpeg"
                          />
                        </Grid.Column>
                        <Grid.Column style={{ marginLeft: 20 }}>
                          <Button
                            style={{ marginTop: 60, marginRight: "70%" }}
                            color="green"
                            size="small"
                            loading={state.loading}
                            onClick={(e) => uploadPhoto(e, 'userPhoto')}
                          >
                            Upload
                          </Button>
                        </Grid.Column>

                        <Grid.Column style={{ marginTop: 20 }}>
                          {state.userPhoto != "" &&
                            state.userPhoto != undefined && (
                              <Image
                                size="small"
                                style={{ cursor: "pointer" }}
                                src={state.userPhotoUrl}
                                // src={this.state.idCard!=""?(STORAGE_URL+ this.state.idCard):this.state.idCard}
                                // src={"https://storage.matex.co.in/minio/address-verification/ID-CARD/179b839b-5bb3-4fbd-9b25-60c25c4bf6da.jpeg"}
                                fluid
                              ></Image>
                            )}
                        </Grid.Column>
                      </div>
                    </div>
                  )}
                  {state.role != 'Field Executive' &&
                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 20 }}>

                      <Grid.Column style={{ marginTop: 20, borderWidth: 10, borderColor: "red", }}>
                        <Checkbox toggle checked={state.remarksAccess} onChange={(e, data) => {
                          setState({ remarksAccess: data.checked })
                        }} label="Access To Edit Remarks" />
                      </Grid.Column>
                      <Grid.Column style={{ marginTop: 20,marginLeft:20, borderWidth: 10, borderColor: "red", }}>
                        <Checkbox toggle checked={state.rotateAccess} onChange={(e, data) => {
                          setState({ rotateAccess: data.checked })
                        }} label="Access To Rotate Photos" />
                        </Grid.Column>
                    </div>
                  }
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="LOA Name"
                      value={state.loaName}
                      onChange={(e) => LoaNameChange(e)}
                      placeholder="LOA Name"
                    />
                  </Grid.Column>
                  <Grid.Column style={{ marginTop: 20 }}>
                    <Form.Input
                      type="text"
                      label="LOA Address"
                      value={state.loaAddress}
                      onChange={(e) => LoaAddressChange(e)}
                      placeholder="LOA Address"
                    />
                  </Grid.Column>

                </Grid.Row>
              </Grid>
            </Form>
          </Modal.Content>
          <Modal.Actions style={{ marginTop: 170 }}>
            <Button color="red" onClick={closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button
              color="black"
              onClick={
                state.editedData ? edituser : saveNewUser
              }
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      </div>
    </div>
  );
}


// function AuditTableCell(props) {
//   function onClick() {
//     props.onClick(props.row);
//   }
//   return (
//     <div style={props.style} onClick={onClick}>
//       {props.text}
//     </div>
//   );
// }

// const mapStateToProps = (state) => {
//   return {
//     auth: state.auth,
//     user: state.user,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators(
//     {
//       logout: logoutAction,
//       fetchUseraction: fetchUseraction,
//       adduser: adduser,
//       fetchId: fetchId,
//       fetchJurisdiction: fetchJurisdiction,
//       EditUser: EditUser,
//       DeleteUser: DeleteUser,
//       userExport: userExport,
//       cityMasterExport: cityMasterExport,
//       ClearUserSession: ClearUserSession
//     },
//     dispatch
//   );
// };

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Users));
export default Users;
