import React, { useEffect, useRef, useState } from "react";
import "react-table-v6/react-table.css";
import { Icon, Button, Modal, Statistic, Grid, Dropdown, Label, Divider, Dimmer, Loader, Form } from "semantic-ui-react";
import {
  userAction,
  fetchQuestions,
  Assignaudits,
  filterAudits,
  Reportdownload,
  DeleteCandidate,
  RejectCases
} from "../../actions/users_action";
import { fetchUseraction, AssignUseraction } from "../../actions/index";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import Questions from "./questionView.jsx";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";
import { DataGrid, GridToolbarContainer, GridToolbarFilterButton, GridToolbarDensitySelector } from "@mui/x-data-grid";
import { setupAxios } from "../../util";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}
function MainView(props) {
  let setEbfcCheckboxRef = useRef()
  const dispatch = useDispatch()
  const auth = useSelector((rdx) => rdx.auth)
  const accessToken = auth.accessToken
  const user = useSelector((rdx) => rdx.user)
  const [state, setStateValue] = useState({
    audits: [],
    EmployeeData: [],
    ResidenceData: [],
    value: "ALL",
    mainView: true,
    id: "",
    auditsType: "",
    modal: false,
    userId: [],
    email: [],
    selectAll: false,
    selection: [],
    fromDate: null,
    toDate: null,
    firstDate: null,
    city: "",
    status: "",
    showDeleteConfirmModal: false,
    showRejectConfirmModal: false,
    rejectedData: "",
    deletedData: "",
    allData: [],
    storeName: "",
    typeAns: "",
    filterData: [],
    filterCheck: false,
    loading: false,
    colorCode: '',
    resonForRejection: '',
    marixRefNums: [],
    marixRefNumsText: "",
    pageSize: 25,
    skip: 0,
    page: 1,
  })
  const setState = (value = {}) => {
    setStateValue((old) => {
      return { ...old, ...value }
    })
  }

  const mountFunction = async () => {
    let token = auth.accessToken;
    let skip = state.page * state.pageSize
    userAction(accessToken, dispatch, { token, skip });
    fetchUseraction(accessToken, dispatch, {
      onSuccess: () => { },
      onFailure: () => { }
    });
    AssignUseraction(accessToken, dispatch);

  }

  useEffect(() => {
    setupAxios(auth.accessToken)
    setTimeout(() => {
      mountFunction()
    }, 1000);
  }, [])

  useEffect(() => {
    if (user.alldata != undefined) {
      user.alldata.map((item) => {
        item.id = item._id
        // console.log("All Data Item",item)
        return item
      })
      console.log("update Alldata")
      setState({ allData: user.alldata });
    }
  }, [user.alldata])

  useEffect(() => {
    if (user.filters != undefined) {
      console.log("update")
      user.filters.map((item) => {
        item.id = item._id
        // console.log("All Data Item",item)
        return item
      })
      setState({ filterData: user.filters });
    }
  }, [user.filters])


  const back = () => {
    setState({
      mainView: true,
    });
  };

  const handleTableViewAuditClick = (store) => {
    // console.log(store, "store");
    if (store.status == 'Completed' || store.status == 'Audited' || store.status == 'Rejected') {
      setState({ loading: true, loadingTitle: "Getting Audit Details", });
      fetchQuestions(accessToken, dispatch, { masterDataId: store._id }, {
        onSuccess: () => {
          setState({
            mainView: false,
            id: store._id,
            auditsType: store.auditsType,
            storeName: store.clientName,
            loading: false
          });
        },
        onFailure: () => {
          setState({
            loading: false
          })
        }
      });
    }
    else {
      alert('Audit Not Done for this Case')
    }

  };

  const handleFromDate = (date) => {
    // var From = moment(date).format("DD-MM-YYYY");
    setState({
      fromDate: date,
      firstDate: date,
    });
  };
  const handleToDate = (date) => {
    setState({
      toDate: date,
    });
  };

  const handlestatusChange = (e, Combo) => {
    setState({
      status: Combo.value,
    });
  };

  const handleTypeChange = (e, Combo) => {
    setState({
      typeAns: Combo.value,
    });
  };

  const filter = () => {
    var From = moment(state.fromDate).format("DD-MM-YYYY");
    var To = moment(state.toDate).format("DD-MM-YYYY");

    var filters = {
      status: state.status,
      fromDate: From,
      toDate: To,
      city: state.city,
      type: state.typeAns,
      color: state.colorCode,
      matrixReferenceNumberList: state.marixRefNums
    };
    setState({ filterCheck: true });
    filterAudits(accessToken, dispatch, filters);
  };
  const downloadReport = (template) => {
    // console.log("Template", template)
    if (template.status == 'Completed' || template.status == 'Audited' || template.status == 'Rejected') {
      setState({ loading: true, loadingTitle: "Downloading Report", });

      Reportdownload(
        accessToken,
        dispatch,
        template._id,
        template.refNo,
        template.checkType,
        template.auditsType,
        'live',
        template.distance_color != undefined ? template.distance_color : '',
        {
          onSuccess: () => {
            setState({ loading: false });
          },
          onFailure: () => {
            alert("Unable to Download")
            setState({ loading: false });
          },
        }
      ).finally(() => {
        setState({ loading: false });
      })
    }
    else {
      alert('Audit Not Done for this Case')
    }

  };

  const toggleSelection = (key, shift, row) => {
    let selection = [];
    // console.log("Toggle Selection", row)
    selection = row
    console.log("selection", selection)
    setState({ selection });
  };


  const addNewUser = () => {
    var email = [];
    if (auth.userUnderMe != undefined) {
      auth.userUnderMe.map((e) => {
        email.push({ text: e.name, value: e.id });
      });
    }
    console.log(email, 'data')
    setState({
      modal: true,
      email,
    });
  };

  const selectuser = (e, Combo) => {
    let userId = state.userId;
    userId[0] = Combo.value;
    setState({
      userId,
    });
  };
  const closeModal = (isClear) => {
    setState({
      modal: false,
      userId: [],
    });
    console.log({ isClear })
    if (isClear == true) {
      setState({ selection: [] })
    }
  };
  const userAssign = () => {
    let masterId = state.selection;
    let userId = state.userId;
    console.log(state.selection, "selected masterIds", userId, "userId");
    Assignaudits(accessToken, dispatch, masterId, userId, {
      onSuccess: async (res) => {
        if (res.data.success == true) {
          await closeModal(true)
          if (state.filterCheck == true) {
            filter()
          }
          else {
            let token = props.token;
            let skip = state.page * state.pageSize
            userAction(accessToken, dispatch, { token, skip });
          }
          setTimeout(() => {
            alert(res.data.message);
          }, 1000);
        }
        else {
          alert(res.data.message);
        }
      },
      onFailure: () => {
        alert("Not assigned");
      },
    });
  };
  const removeDuplicates = (e) => {
    let newArray = [];

    // Declare an empty object
    let UniqueObject = {};

    // Loop for the array elements
    for (let i in e) {
      // Extract the title
      let objTitle = e[i]["text"];

      // Use the title as the index
      // console.log(objTitle)
      if (objTitle) UniqueObject[objTitle] = e[i];
    }
    // console.log(UniqueObject,"UniqueObject")

    // Loop to push unique object into array
    for (let i in UniqueObject) {
      newArray.push(UniqueObject[i]);
    }

    return newArray;
  };

  const deleteCandidateConfirm = (data) => {
    // console.log(data, "data");
    setState({ showDeleteConfirmModal: true, deletedData: data._id });
  };

  const RejectCandidateConfirm = (data) => {
    // console.log(data, "data");
    setState({ showRejectConfirmModal: true, rejectedData: data._id })
  }

  const closeCandidateDeleteModal = () => {
    setState({ showDeleteConfirmModal: false, deletedData: "", showRejectConfirmModal: false, rejectedData: "" });
  };

  const reasonChange = (e) => {
    setState({
      resonForRejection: e.target.value,
    });
  };

  const deleteCandidate = () => {
    setState({ showDeleteConfirmModal: false });
    DeleteCandidate(accessToken, dispatch, state.deletedData, {
      onSuccess: () => {
        let token = props.token;
        let skip = state.page * state.pageSize
        userAction(accessToken, dispatch, { token, skip });
        // this.setState({allData:user.alldata})
      },
      onFailure: () => {
        alert("Permission Denied");
      },
    });
  };

  const rejectCandidate = () => {
    if (state.resonForRejection === '') {
      alert("Please Specify Reason for Rejection")
    }
    else {
      let data = {
        masterId: state.rejectedData,
        reason: state.resonForRejection
      }
      RejectCases(accessToken, dispatch, data, {
        onSuccess: () => {
          let token = props.token;
          let skip = state.page * state.pageSize
          userAction(accessToken, dispatch, { token, skip });
          setState({ showRejectConfirmModal: false, allData: user.alldata, resonForRejection: "" });
          alert('Case Rejected Successfully')
        },
        onFailure: () => {
          alert("Permission Denied");
        },
      });
    }


  };



  const changeMatrixRefNos = (text) => {
    // console.log(text)
    setState({ marixRefNumsText: text })
    //marixRefNums
    var splitedData = (text + "").toString().split(",")
    // console.log(splitedData)
    var marixRefNums = []
    splitedData.map((item) => {
      var refNo = (item + "").toString().trim()
      if (refNo != "") {
        marixRefNums.push(refNo)
      }
    })
    setState({ marixRefNums })
    // console.log(marixRefNums)
  }

  const onPageChanges = (pageNo) => {
    let skip = pageNo * state.pageSize
    let token = props.token;
    userAction(accessToken, dispatch, { token, skip });
    setState({ page: pageNo })
  }

  var cityData = [{ text: "All", value: "All" }];
  var statusData = [{ text: "All", value: "All" }];
  var typeOption = [
    { text: "Employee", value: "Employee" },
    { text: "Residence", value: "Residence" },
  ];
  var typeColor = [
    { text: "Green", value: "Green" },
    { text: "Orange", value: "Orange" },
    { text: "Red", value: "Red" }
  ];
  let uniqueCity;
  let uniqueStatus;
  if (user.alldata != undefined) {
    var data = user.alldata;
    data.map((e) => {
      statusData.push({ text: e.status, value: e.status });
      cityData.push({ text: e.city, value: e.city });
    });
  }
  if (cityData.length > 0) {
    uniqueCity = removeDuplicates(cityData);
  }
  if (statusData.length > 0) {
    uniqueStatus = removeDuplicates(statusData);
  }

  let ResidencecolumnsDataGrid = [
    {
      headerName: "ClientName",
      field: "clientName",
      style: { textAlign: "center", cursor: "pointer" },
      width: 200,
      renderCell: (row) => (
        <AuditTableCell
          row={row.row}
          text={row.row.clientName}
          onClick={() => handleTableViewAuditClick(row.row)}
        />
      ),
    },
    {
      headerName: "Candidate Name",
      field: "candidateName",
      style: { textAlign: "center", cursor: "pointer" },
      width: 200,
      renderCell: (row) => (
        <AuditTableCell
          row={row.row}
          text={row.row.candidateName}
          onClick={() => handleTableViewAuditClick(row.row)}
        />
      ),
    },
    {
      headerName: "Matrix Ref No",
      field: "refNo",
      style: { textAlign: "center", cursor: "pointer" },
      width: 120,
      renderCell: (row) => (
        <AuditTableCell
          row={row.row}
          text={row.row.refNo}
          onClick={() => handleTableViewAuditClick(row.row)}
        />
      ),
    },
    {
      headerName: "Check Type",
      field: "checkType",
      style: { textAlign: "center", cursor: "pointer" },
      renderCell: (row) => (
        <AuditTableCell
          row={row.row}
          text={row.row.checkType}
          onClick={() => handleTableViewAuditClick(row.row)}
        />
      ),
    },
    {
      headerName: "City",
      field: "city",
      style: { textAlign: "center", cursor: "pointer" },
      renderCell: (row) => (
        <AuditTableCell
          row={row.row}
          text={row.row.city}
          onClick={() => handleTableViewAuditClick(row.row)}
        />
      ),
    },
    {
      headerName: "Status",
      field: "status",
      style: { textAlign: "center", cursor: "pointer" },
      renderCell: (row) => (
        <AuditTableCell
          row={row.row}
          text={row.row.status}
          onClick={() => handleTableViewAuditClick(row.row)}
        />
      ),
    },
    {
      headerName: "Assignee Name",
      field: "assigneeLoginId",
      style: { textAlign: "center", cursor: "pointer" },
      width: 150,
      renderCell: (row) => (
        <AuditTableCell
          row={row.row}
          text={row.row.assigneeLoginId}
          onClick={() => handleTableViewAuditClick(row.row)}
        />
      ),
    },
    {
      headerName: "Assigned Date",
      field: "assignedAt",
      style: { textAlign: "center", cursor: "pointer" },
      // type: 'date',
      renderCell: (row) => (
        <AuditTableCell
          row={row.row}
          text={row.row.assignedAt ? row.row.assignedAt.substring(0, 10) : ''}
          onClick={() => handleTableViewAuditClick(row.row)}
        />
      ),
    },
    {
      headerName: "Color Code",
      field: "distance_color",
      style: { textAlign: "center", cursor: "pointer" },
      width: 80,
      renderCell: (row) => (
        <AuditTableCell
          row={row.row}
          text={row.row.distance_color ? row.row.distance_color : ''}
          onClick={() => handleTableViewAuditClick(row.row)}
        />
      ),
    },
    {
      headerName: "Address",
      field: "address",
      style: { textAlign: "center", cursor: "pointer" },
      width: 260,
      renderCell: (row) => (
        <AuditTableCell
          row={row.row}
          text={row.row.address}
          onClick={() => handleTableViewAuditClick(row.row)}
        />
      ),
    },
    {
      headerName: "Report",
      field: "report",
      style: { textAlign: "center", cursor: "pointer" },
      sortable: false,
      width: 60,
      filterable: false,
      renderCell: (row) => {
        // if (row._id) {
        return (
          <Icon
            size="large"
            color="red"
            name="file pdf outline"
            onClick={() => downloadReport(row.row)}
          />
        );
        // }
      },
    },
    {
      headerName: "Delete",
      field: "delete",
      style: { textAlign: "center" },
      width: 60,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Icon
          size="large"
          color="red"
          name="trash alternate"
          onClick={() => deleteCandidateConfirm(row.row)}
        />
      ),
    },
    {
      headerName: "Reject",
      field: "reject",
      style: { textAlign: "center" },
      width: 60,
      sortable: false,
      filterable: false,
      renderCell: (row) => (
        <Icon
          size="large"
          color="red"
          name="close"
          onClick={() => RejectCandidateConfirm(row.row)}
        />
      ),
    },
  ];

  return (
    <div
      style={{
        flexGrow: 1,
        display: "flex",
        flexFlow: "column",
        height: "90vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <Dimmer active={state.loading}>
        <Loader>{state.loadingTitle ?? "Loading..."}</Loader>
      </Dimmer>
      {state.mainView && (
        <div >
          <h1
            style={{
              marginLeft: 20,
              // marginBottom: 35,
              color: "#212121",
              display: "inline-block",
              fontSize: 25,
              fontFamily: "sans-serif",
              fontWeight: "bold",
            }}
          >
            Physical Address Verification
          </h1>
          <Button
            color="green"
            disabled={state.selection.length === 0}
            style={{ float: "right" }}
            icon
            labelPosition="right"
            onClick={addNewUser}
          >
            <Icon name="add" />
            Assign
          </Button>

          <Divider />
          <div style={{ flexGrow: 1, display: 'flex' }}>
            <Grid
              style={{
                marginLeft: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Grid.Row columns={6}>
                <Grid.Column>
                  <Form widths={'equal'}>
                    <div>
                      <h5>Matrix Ref No</h5>
                      <Form.Input
                        type="text"
                        value={state.marixRefNumsText}
                        placeholder="i.e MATRIXREF1,MATRIXREF2"
                        onChange={(value) => { changeMatrixRefNos(value.target.value) }}
                      />
                    </div>
                  </Form>
                </Grid.Column>
                <Grid.Column>
                  <Statistic color="blue">
                    <Statistic.Label
                      style={{
                        fontSize: 16,
                        color: "black",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Assigned To TL
                    </Statistic.Label>
                    <Statistic.Value>
                      {Object(user.metrics)["Assigned To TL"]}
                    </Statistic.Value>
                  </Statistic>
                </Grid.Column>
                <Grid.Column>
                  <Statistic color="blue">
                    <Statistic.Label
                      style={{
                        fontSize: 16,
                        color: "black",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Assigned To TM
                    </Statistic.Label>
                    <Statistic.Value>
                      {Object(user.metrics)["Assigned To TM"]}
                    </Statistic.Value>
                  </Statistic>
                </Grid.Column>
                <Grid.Column>
                  <Statistic color="blue">
                    <Statistic.Label
                      style={{
                        fontSize: 16,
                        color: "black",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Assigned To FE
                    </Statistic.Label>
                    <Statistic.Value>
                      {Object(user.metrics)["Assigned To FE"]}
                    </Statistic.Value>
                  </Statistic>
                </Grid.Column>
                <Grid.Column>
                  <Statistic color="blue">
                    <Statistic.Label
                      style={{
                        fontSize: 16,
                        color: "black",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Audited
                    </Statistic.Label>
                    <Statistic.Value>
                      {Object(user.metrics)["Audited"]}
                    </Statistic.Value>
                  </Statistic>
                </Grid.Column>
                <Grid.Column>
                  <Statistic color="blue">
                    <Statistic.Label
                      style={{
                        fontSize: 16,
                        color: "black",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Completed
                    </Statistic.Label>
                    <Statistic.Value>
                      {Object(user.metrics)["Completed"]}
                    </Statistic.Value>
                  </Statistic>
                </Grid.Column>
              </Grid.Row>


              <Grid.Row columns={6}>
                <Grid.Column>
                  <Dropdown
                    placeholder="Status"
                    fluid
                    search
                    selection
                    style={{ height: "5vh" }}
                    value={state.status}
                    options={uniqueStatus}
                    onChange={handlestatusChange}
                  />
                </Grid.Column>
                <Grid.Column>
                  <Dropdown
                    placeholder="Verification type"
                    fluid
                    search
                    selection
                    style={{ height: "5vh" }}
                    value={state.typeAns}
                    options={typeOption}
                    onChange={handleTypeChange}
                  />
                </Grid.Column>
                <Grid.Column
                  style={{
                    display: "flex",
                    flex: 1,
                    width: "100%",
                    marginTop: "5px",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={state.fromDate}
                      onChange={handleFromDate}
                      format="dd/MM/yyyy"
                      placeholder="From-Date"
                    />
                  </MuiPickersUtilsProvider>
                </Grid.Column>{" "}
                <Grid.Column
                  style={{
                    display: "flex",
                    flex: 1,
                    width: "100%",
                    marginTop: "5px",
                  }}
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      value={state.toDate}
                      onChange={handleToDate}
                      format="dd/MM/yyyy"
                      placeholder="To-Date"
                    />
                  </MuiPickersUtilsProvider>
                </Grid.Column>
                <Grid.Column>
                  <Label
                    color="blue"
                    style={{
                      cursor: "pointer",
                      width: "70%",
                      marginTop: "5px",

                      textAlign: "center",
                    }}
                    size="large"
                    onClick={filter}
                  >
                    Filter{" "}
                  </Label>
                </Grid.Column>
                <Grid.Column>
                  <Label
                    color={
                      state.filterCheck === false ? "grey" : "orange"
                    }
                    style={{
                      cursor: state.filterCheck === false ? "not-allowed" : "pointer",
                      width: "70%",
                      marginTop: "5px",

                      textAlign: "center",
                    }}
                    size="large"
                    onClick={
                      state.filterCheck === false
                        ? null
                        : () => {
                          setState({
                            filterAudits: [],
                            filterCheck: false,
                            toDate: null,
                            fromDate: null,
                            typeAns: "",
                            status: "",
                            city: "",
                            marixRefNumsText: "",
                            marixRefNums: []
                          });
                          let token = props.token;
                          let skip = state.page * state.pageSize
                          userAction(accessToken, dispatch, { token, skip });
                        }
                    }
                  >
                    Reset Filter{" "}
                  </Label>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>

          <div style={{ marginTop: 30, marginLeft: 15, marginRight: 15, height: 900, width: "96dvw" }}>
            <DataGrid
              rows={state.filterCheck === false ? state.allData : state.filterData}
              filterMode='client'
              // disableColumnMenu
              disableColumnSelector
              ref={setEbfcCheckboxRef}
              // filterModel={}
              components={{ Toolbar: CustomToolbar }}
              componentsProps={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
              density="compact"
              checkboxSelection
              disableSelectionOnClick
              isRowSelectable={(params) => (params.row.status != 'Audited' && params.row.status != 'Completed')}
              onRowSelectionModelChange={(data) => { toggleSelection("key", null, data) }}
              columns={ResidencecolumnsDataGrid}
              pagination

              // onCellClick={(data)=>{console.log("Cell Click",data)}}
              pageSize={state.pageSize}
              page={state.page}
              rowsPerPageOptions={[25, 50, 100]}
              onPageSizeChange={(size) => { setState({ pageSize: size }) }}
              onPageChange={(newPage) => onPageChanges(newPage)}
              rowHeight={50}
            />
          </div>

        </div>
      )}

      <Modal open={state.modal} onClose={closeModal} size="small">
        <Modal.Header>
          <Label color="blue">Assign Audits</Label>
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Dropdown
              fluid
              search
              selection
              placeholder="select user"
              options={state.email}
              onChange={selectuser}
            />
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions style={{ marginTop: 130 }}>
          <Button color="green" onClick={userAssign}>
            Assign
          </Button>
          <Button color="red" onClick={closeModal}>
            <Icon name="remove" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size={"mini"}
        open={state.showDeleteConfirmModal}
        onClose={closeCandidateDeleteModal}
      >
        <Modal.Content>
          <p>Are you sure you want to delete this data?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={closeCandidateDeleteModal}>
            Cancel
          </Button>
          <Button primary onClick={deleteCandidate}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size={"mini"}
        open={state.showRejectConfirmModal}
        onClose={closeCandidateDeleteModal}
      >
        <Modal.Content>
          <p>Are you sure you want to Reject this Case?</p>
        </Modal.Content>
        <Modal.Actions>
          <Form>
            <h4 style={{ float: 'left' }}>{"Reason for Rejection"}</h4>
            <Form.Input
              type="text"
              value={state.resonForRejection}
              onChange={(e) => reasonChange(e)}
              placeholder="Reason for Rejection"
            />
          </Form>
          <br />
          <br />
          <Button negative onClick={closeCandidateDeleteModal}>
            Cancel
          </Button>
          <Button primary onClick={rejectCandidate}>
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      {!state.mainView && (
        <Questions
          onClose={back}
          auditsType={state.auditsType}
          id={state.id}
          loading={state.loading}
          storeName={state.storeName}
        />
      )}
    </div>
  );
}


function AuditTableCell(props) {
  function onClick() {
    props.onClick(props.row);
  }
  return (
    <div style={props.style} onClick={onClick}>
      {props.text}
    </div>
  );
}
export default MainView
