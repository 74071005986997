import React, { useEffect } from "react";
import { Segment, Menu, Dropdown } from "semantic-ui-react";
import { useSelector } from "react-redux";
import { logoutAction } from "../actions/index";
import { isAdmin } from "../util";
import { useNavigate } from "react-router";
import MainView from "./Maincomponents/MainView.jsx";
import matrix from "../Images/matrix-logo.png";
import { useDispatch } from 'react-redux'
export function Home() {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector((rdx) => rdx.auth)
  const accessToken = auth.accessToken
  const user = useSelector((rdx) => rdx.user)
  
  useEffect(() => {
    if (auth.isLoggedIn == false) {
      navigation("/login");
    }
  }, [auth.isLoggedIn])

  const logout = () => {
    let email = user.loginId;
    logoutAction(accessToken, dispatch, email);
    setTimeout(() => {
      navigation("/login", { replace: true });
    }, 1000);
  };

  const control = () => {
    navigation("/controlpanel");
  };
  return (
    <div style={{ height: "100vh" }}>
      <Segment raised style={{ backgroundColor: "#ffffff", height: '8vh' }}>
        <div style={{ display: "inline-block" }}>
          <img
            style={{
              height: 60,
              marginTop: -13,
              float: "left",
            }}
            src={matrix}
          />
        </div>

        <Menu.Menu
          style={{
            display: "inline",
            float: "right",
            marginTop: 5,
            marginRight: 50,
          }}
        >
          <Dropdown pointing text={auth.Name}>
            <Dropdown.Menu>
              {isAdmin(auth) && (
                <Dropdown.Item onClick={control}>
                  Control Panel
                </Dropdown.Item>
              )}
              {auth.role == "Team Member" && (
                <Dropdown.Item onClick={control}>
                  Control Panel
                </Dropdown.Item>
              )}
              {auth.role == "Team Leader" && (
                <Dropdown.Item onClick={control}>
                  Control Panel
                </Dropdown.Item>
              )}
              <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Segment>

      <Segment
        basic
        style={{
          height: "96vh",
          padding: "10px 0px 0px 0px",
          backgroundColor: "white",
          overflowY: "scroll",
        }}
      >
        <MainView token={auth.accessToken} />
      </Segment>
    </div>
  );

}
export default Home;
