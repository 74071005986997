import 'semantic-ui-css/semantic.min.css';
import React from "react";
import { Provider, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Store from "./store";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { CssBaseline } from '@mui/material';

import Login from "./components/Login.jsx";
import Home from "./components/Home.jsx";
import ControlPanel from "./components/ControlPanel.jsx"
import ForgotPassword from "./components/ForgotPassword.jsx";
import CustomerQues from './components/CustomerQues.jsx';


const ThemedRouter = (props) => {
  const currentTheme = useSelector(state => state.auth)
  const darkTheme = createTheme({
    palette: {
      mode: currentTheme.theme,
    },
  });
  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}

function App() {
  let persistor = persistStore(Store);
  return (
    <Provider store={Store}>
      <PersistGate persistor={persistor}>
        <ThemedRouter>
          <Router>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/home" element={<Home />} />
              <Route path="/login" element={<Login />} />
              <Route path="/controlpanel" element={<ControlPanel />} />
              <Route path="/forgotPassword" element={<ForgotPassword />} />
              <Route path="/customerQues" element={<CustomerQues />} />
            </Routes>
          </Router>
        </ThemedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;