import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Header, Image, Message, Segment, Card, Icon, GridColumn, GridRow, Input, Modal } from "semantic-ui-react";
import { isBrowser } from "react-device-detect";
import { loginAction, forgotPassword } from "../actions/index";
import { userdata } from "../actions/users_action";
import { useSelector, useDispatch } from "react-redux";
import { isLoggedIn } from "../util";
import { useNavigate } from "react-router";
import { toast, SemanticToastContainer } from "react-semantic-toasts";
import matrix from "../Images/matrix-logo.png";
import "../styles/index.css";

export function Login() {
  const [state, setStateValue] = useState({
    username: "",
    password: "",
    email: false,
    EmailId: "",
    platform: "Web",
  })
  const auth = useSelector((rdx) => rdx.auth)
  const accessToken = auth.accessToken
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const islogin = auth.isLoggedIn

  const setState = (value = {}) => {
    setStateValue((old) => {
      return { ...old, ...value }
    })
  }
  useEffect(() => {
    // setTimeout(() => {
    if (auth.isLoggedIn == true) {
      navigation("/home");
    }
    else {
      localStorage.removeItem('persist:physicalAddressVerificationWeb')
    }
    // },1000)
  }, [auth.isLoggedIn])
  // useEffect(() => {
  //   if (islogin) {
  //     navigation("/home");
  //   }
  // }, [auth.isLoggedIn])


  // shouldComponentUpdate(nextProps, nextState) {
  //   if (isLoggedIn(nextProps.auth)) {
  //     this.props.history.push(`/home`);
  //     return false;
  //   }
  //   return true;
  // }
  const handleUsernameChange = (e) => {
    setState({ username: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setState({ password: e.target.value });
  };

  const authenticate = () => {
    userdata(accessToken, dispatch, state.username);
    // this.props.history.push('/home')
    loginAction(accessToken, dispatch, {
      username: state.username,
      password: state.password,
      platform: state.platform
    }, dispatch);
  };

  const email = () => {
    setState({ email: true });
  };

  const Close = () => {
    setState({ email: false });
  };

  const SendEmail = () => {
    let email = state.EmailId;
    let data = {
      loginId: email,
    };
    forgotPassword(accessToken, dispatch, data);
  };
  const handleEmail = (e) => {
    setState({ EmailId: e.target.value });
  };

  const loginBoxStyle = {
    borderRadius: 6,
    WebkitBoxShadow: "10px 11px 81px 3px rgba(191,191,191,1)",
    MozBoxShadow: "10px 11px 81px 3px rgba(191,191,191,1)",
    boxShadow: "10px 11px 81px 3px rgba(191,191,191,1)",
  };
  return (
    <Grid
      textAlign="center"
      style={{ height: "100vh" }}
      verticalAlign="middle"
    >
      <Grid.Column style={{ maxWidth: 500, borderRadius: "15px" }}>
        <Form className="auth-inner">
          <img src={matrix} style={{ height: "100px", width: "150px", marginBottom: '10px', backgroundSize: 'contain' }} />
          {/* <img src={matrix} /> */}
          <Form.Input
            fluid
            icon="user"
            iconPosition="left"
            placeholder="E-mail address"
            onChange={handleUsernameChange}
          />
          <Form.Input
            fluid
            icon="lock"
            iconPosition="left"
            placeholder="Password"
            type="password"
            onChange={handlePasswordChange}
          />
          <div style={{ marginBottom: '30px' }}></div>

          <Button color="blue" fluid size="large" onClick={authenticate}>
            Login
          </Button>
        </Form>
        <Message color="blue">
          <p
            style={{ textDecorationLine: "underline", cursor: "pointer" }}
            onClick={email}
          >
            Forgot Password?
          </p>
        </Message>
      </Grid.Column>
      {state.email === true && (
        <Modal
          size="mini"
          dimmer="blurring"
          open={state.email}
          onClose={Close}
        >
          <SemanticToastContainer />
          <Modal.Header>Enter LoginId</Modal.Header>
          <Modal.Content>
            <Form>
              <Input
                type="email"
                iconPosition="left"
                style={{ width: 300 }}
                placeholder="Enter the Email"
                onChange={handleEmail}
              >
                <Icon name="at" />
                <input />
              </Input>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button color="red" onClick={Close}>
              Cancel
            </Button>
            <Button color="green" onClick={SendEmail}>
              Send
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Grid>
  );
}

export default Login
