import React, { useState } from 'react';
import { Button, Form, Modal, Segment, Input, Grid, Label } from 'semantic-ui-react';
import { resetpassword } from '../actions/index.js';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import jwt from 'jwt-decode'
import { toast, SemanticToastContainer } from "react-semantic-toasts";

export function ForgotPassword(props) {
    const dispatch = useDispatch()
    const auth = useSelector((rdx) => rdx.auth)
    const accessToken = auth.accessToken
    const filter = new URLSearchParams(props.location.search)
    const [state, setStateValue] = useState({
        hidePass: true,
        hidePass1: true,
        password: "",
        confirmpassword: "",
        open: false,
        email: filter.get("email"),
        isRestLinkExpired: false,
        reset_access_token: filter.get('accessToken')
    })

    const setState = (value = {}) => {
        setStateValue((old) => {
            return { ...old, ...value }
        })
    }


    const isResertPasswordTokenValid = (token) => {
        const user = jwt(token);
        const expire_time = new Date(user.expire_at)
        if (expire_time < new Date()) {
            setState({ isRestLinkExpired: true })
        }

    }

    const onReset = () => {
        let filter1 = new URLSearchParams(props.location.search)

        let email = filter1.get("email");
        let new_password = state.password;
        let confirm_password = state.confirmpassword;
        let _token = filter1.get('accessToken');
        if (email === null || email === undefined)
            alert('Email or Login Id is Missing')
        else if (_token === null || _token === undefined)
            alert('Access token is Missing')
        else {
            var ResetPassword = {
                loginId: email,
                newPassword: new_password,
                confirmPassword: confirm_password,
                token: _token
            };

            resetpassword(accessToken, dispatch, ResetPassword);
            setState({ password: '', confirmpassword: '' })
        }


    }

    const loginClickable = () => {
        setState({
            hidePass: false
        })
    }

    const loginClickable1 = () => {
        setState({
            hidePass1: false
        })
    }


    const onNewPassword = e => {
        setState({
            password: e.target.value
        })
    }

    const ConfirmPassword = e => {
        setState({
            confirmpassword: e.target.value
        })
    }



    return (

        <div style={{ height: "100%" }}>
            <div>
                <Segment
                    raised
                    style={{ backgroundColor: "#wefwef(", height: 60, marginBottom: 0 }}

                >

                </Segment>
            </div>

            {state.isRestLinkExpired === true && (
                alert('Link Expired')
            )}
            {state.isRestLinkExpired === false && (

                <div style={{ position: "absolute", top: 150, left: 400, width: "550px", padding: 40, border: "2px outset", boxSizing: "border-box", backgroundColor: "lightblue", display: "inline-block", borderRadius: "7px" }}>
                    <h1>Reset password</h1>

                    <Form>
                        <Form.Field>
                            <label>New password</label>
                            <Input
                                action={{ icon: 'eye', onClick: () => { loginClickable() } }}
                                type={state.hidePass ? "password" : "text"}
                                placeholder='Enter new password...'
                                value={state.password}
                                onChange={onNewPassword}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Confirm Password</label>
                            <Input
                                action={{ icon: 'eye', onClick: () => { loginClickable1() } }}
                                type={state.hidePass1 ? "password" : "text"}
                                placeholder='Confirm password...'
                                value={state.confirmpassword}
                                onChange={ConfirmPassword}
                            />
                        </Form.Field>

                        <Form.Input>
                            <Label>The Characters should Contain (A-Z,@#!$&%,1-10) ("e.g:Siva@123")</Label>
                        </Form.Input>
                        <Form.Field>
                            <Button color='green' onClick={onReset} >
                                Save
                            </Button>
                        </Form.Field>
                    </Form>
                </div>

            )}


        </div>
    )



}
// const mapStateToProps = (state) => {
//     return {
//         // reset: state.reset,
//     };
// }

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators({
//         reset: resetpassword,
//     }, dispatch);
// }

export default ForgotPassword;
