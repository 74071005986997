import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, withRouter } from "react-router";
import { isLoggedIn, isAdmin } from "./../util";
import { selectMenuAction, logoutAction } from "../actions/index";
import { Container, Segment, Input, Icon, Label } from "semantic-ui-react";
import { Sidebar, Menu, Header, Image, Button, Dropdown, Tab, } from "semantic-ui-react";
import Users from "../components/ControlPanelComponents/Users.jsx";
import Report from "../components/ControlPanelComponents/Report.jsx";
import FileUpload from "../components/ControlPanelComponents/FileUpload.jsx";
import matrix from "../Images/matrix-logo.png";
import RejectedCases from "./ControlPanelComponents/RejectedCases.jsx";
function ControlPanel(props) {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector((rdx) => rdx.auth)
  const accessToken = auth.accessToken
  const user = useSelector((rdx) => rdx.user)
  const islogin = auth.isLoggedIn
  const [state, setStateValue] = useState({
    sideBarVisible: true,
  })

  const setState = (value = {}) => {
    setStateValue((old) => {
      return { ...old, ...value }
    })
  }

  const handleItemClick = (e, { name }) => selectMenuAction(accessToken,dispatch, name);

  const logout = () => {
    logoutAction(accessToken,dispatch, user.loginId);
    setTimeout(() => {
      navigation("/login", { replace: true });
    }, 1000);
  };

  useEffect(() => {
    if (auth.isLoggedIn == false) {
      navigation("/login");
    }
    // if (!isAdmin(auth) && auth.role == 'Team Member') {
    // navigation("/", { replace: true });
    // }
  }, [auth.isLoggedIn])


  const toggleSidebar = () => {
    setState({ sideBarVisible: !state.sideBarVisible });
  };

  const openHome = () => {
    navigation(`/home`);
  };

  const others = [
    {
      menuItem: {
        key: "reports",
        icon: "cloud download",
        content: "Reports",
      },

      render: () => (
        <Tab.Pane>
          <Report />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "master",
        icon: "cloud upload",
        content: "Master data",
      },

      render: () => (
        <Tab.Pane>
          <FileUpload />
        </Tab.Pane>
      ),
    },
  ];
  const teamLeader = [
    {
      menuItem: {
        key: "reports",
        icon: "cloud download",
        content: "Reports",
      },

      render: () => (
        <Tab.Pane>
          <Report />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "master",
        icon: "cloud upload",
        content: "Master data",
      },

      render: () => (
        <Tab.Pane>
          <FileUpload />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "rejected_cases",
        icon: "close",
        content: "Rejected Cases",
      },

      render: () => (
        <Tab.Pane>
          <RejectedCases />
        </Tab.Pane>
      ),
    },
  ];
  const admin = [
    {
      menuItem: { key: "users", icon: "users", content: "Users" },
      render: () => (
        <Tab.Pane>
          <Users />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "reports",
        icon: "cloud download",
        content: "Reports",
      },

      render: () => (
        <Tab.Pane>
          <Report />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "master",
        icon: "cloud upload",
        content: "Master data",
      },

      render: () => (
        <Tab.Pane>
          <FileUpload />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: "rejected_cases",
        icon: "close",
        content: "Rejected Cases",
      },

      render: () => (
        <Tab.Pane>
          <RejectedCases />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div style={{ height: "100vh" }}>
      <Segment raised style={{ backgroundColor: "white", height: '8vh' }}>
        <div style={{ display: "inline-block" }}>
          <img
            style={{
              height: 60,
              marginTop: -13,
              float: "left",
            }}
            src={matrix}
          />
        </div>
        <Menu.Menu
          style={{ display: "inline", float: "right", marginTop: 5, marginRight: 50, }}
        >
          <Dropdown pointing text={auth.Name}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={openHome}>Home</Dropdown.Item>
              <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Segment>
      <Segment
        basic
        style={{ height: "92vh", }}
      >
        {auth.role === "Admin" && <Tab panes={admin} />}
        {auth.role === "Team Member" && <Tab panes={others} />}
        {auth.role === "Team Leader" && <Tab panes={teamLeader} />}
      </Segment>

    </div>
  );
}


// const mapStateToProps = (state) => {
//   return {
//     auth: state.auth,
//     home: state.home,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators(
//     {
//       logout: logoutAction,
//       selectMenu: selectMenuAction,
//     },
//     dispatch
//   );
// };

export default ControlPanel;
