import React, { useState } from 'react';
import { Button, Form, Modal, Segment, Input, Grid, Label } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch,useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { toast, SemanticToastContainer } from "react-semantic-toasts";
import { CustomerFeedbackaction } from '../actions/index';
function CustomerQues(props) {
    const dispatch = useDispatch()
    const auth = useSelector((rdx) => rdx.auth)
    const accessToken = auth.accessToken
    let filter = new URLSearchParams(props.location.search)
    const [state, setStateValue] = useState({
        matrixRefNo: filter.get("matrixRefNo"),
        verficationDate: '',
        behaviourExe: '',
        authExe: '',
        formallyDressed: '',
        demandMoney: '',
        idCardCarried: '',
        feedback: ''
    })

    const setState = (value = {}) => {
        setStateValue((old) => {
            return { ...old, ...value }
        })
    }

    const dateOnChange = e => {
        setState({ verficationDate: e.target.value })
    }

    const behaviourExeOnChange = e => {
        setState({ behaviourExe: e.target.value })
    }

    const authOnChange = e => {
        setState({ authExe: e.target.value })
    }

    const formalOnchange = e => {
        setState({ formallyDressed: e.target.value })
    }

    const demandOnChange = e => {
        setState({ demandMoney: e.target.value })
    }

    const idOnChange = e => {
        setState({ idCardCarried: e.target.value })
    }

    const feedbackOnChange = e => {
        setState({ feedback: e.target.value })
    }

    const saveform = () => {
        let data = {
            dateOfVerification: state.verficationDate,
            behaviourOfExecutive: state.behaviourExe,
            authorizationCarriedbyFieldExe: state.authExe,
            fieldExecutiveFormallyDressed: state.formallyDressed,
            fieldExecutiveDemandforMoney: state.demandMoney,
            identiyCardCarried: state.idCardCarried,
            feedback: state.feedback
        }
        let matrixRefNo = state.matrixRefNo;

        if (state.verficationDate === '' || state.behaviourExe === '' || state.authExe === '' || state.formallyDressed === '' || state.demandMoney === '' || state.idCardCarried === '' || state.feedback === '') {
            alert("Please Fill all the Details")
        }
        else {
            CustomerFeedbackaction(accessToken,dispatch, data, matrixRefNo, {
                onSuccess: () => {
                    alert('Feedback Save Successfully')
                    setState({
                        verficationDate: '',
                        behaviourExe: '',
                        authExe: '',
                        formallyDressed: '',
                        demandMoney: '',
                        idCardCarried: '',
                        feedback: ''
                    })
                },
                onFailure: () => {
                    alert('Feedback Not Saved')
                }
            })
        }
    }




    return (

        <div style={{ height: "100%" }}>


            <div style={{ width: "550px", padding: 40, border: "2px outset", boxSizing: "border-box", backgroundColor: "lightblue", display: "inline-block", borderRadius: "7px" }}>
                <h1>Feedback Form</h1>

                <Form>
                    <Form.Field>
                        <label>Date of Visit for verification</label>
                        <input placeholder='Date of Visit for verification'
                            value={state.verficationDate}
                            onChange={dateOnChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Behavior of the executive</label>
                        <input placeholder='Behavior of the executive'
                            value={state.behaviourExe}
                            onChange={behaviourExeOnChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Is Letter of Authorization is carried by our Field Executive?</label>
                        <input placeholder='Is Letter of Authorization is carried by our Field Executive?'
                            value={state.authExe}
                            onChange={authOnChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>If Field Executive formally dressed?</label>
                        <input placeholder='If Field Executive formally dressed?'
                            value={state.formallyDressed}
                            onChange={formalOnchange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>If Field Executive demanded for money?</label>
                        <input placeholder='If Field Executive demanded for money?'
                            value={state.demandMoney}
                            onChange={demandOnChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Identity card Carried</label>
                        <input placeholder='Identity card Carried'
                            value={state.idCardCarried}
                            onChange={idOnChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Feedback</label>
                        <input placeholder='Feedback'
                            value={state.feedback}
                            onChange={feedbackOnChange}
                        />
                    </Form.Field>

                    <Button
                        type='submit'
                        color='green'
                        onClick={saveform}
                    >Submit</Button>
                </Form>


            </div>

        </div>
    )
}



// const mapStateToProps = (state) => {
//     return {
//         reset: state.reset,
//     };
// }

// const mapDispatchToProps = (dispatch) => {
//     return bindActionCreators({
//         CustomerFeedbackaction
//     }, dispatch);
// }

export default CustomerQues;
