import { actions } from "react-table";
import {
  USERLIST_SUCCESS_ACTION,
  USERLIST_FAILURE_ACTION,
  FETCH_QUESTIONS_SUCCESS_ACTION,
  FETCH_QUESTIONS_FAILURE_ACTION,
  FETCH_ID_SUCCESS_ACTION,
  FETCH_ID_FAILURE_ACTION,
  SUBMIT_AUDITS_SUCCESS_ACTION,
  SUBMIT_AUDITS_FAILURE_ACTION,
  ASSIGN_AUDITS_SUCCESS_ACTION,
  ASSIGN_AUDITS_FAILURE_ACTION,
  FILTER_SUCCESS_ACTION,
  FILTER_FAILURE_ACTION,
  SET_EMAIL,
  ASSIGN_USERS_SUCCESS_ACTION,
  ASSIGN_USERS_FAILURE_ACTION,
  GET_REJECT_CASES_SUCCESS_ACTION
} from "../actions/types";

const INIT_STATE = {};


export const UserReducer = (state = INIT_STATE, action) => {
  // state = Object.assign({}, state, {});
  switch (action.type) {
    case USERLIST_SUCCESS_ACTION:
      return {
        ...state,
        alldata: JSON.parse(JSON.stringify(action.payload.data)),
        metrics: action.payload.counts,
        usererror: false,
      }
    case USERLIST_FAILURE_ACTION:
      return {
        ...state,
        usererror: true
      }
    case FETCH_QUESTIONS_SUCCESS_ACTION:
      return {
        ...state,
        questions: action.payload,
        quesError: false,
      }
    case FETCH_QUESTIONS_FAILURE_ACTION:
      return {
        ...state,
        quesError: true
      }
    case SUBMIT_AUDITS_SUCCESS_ACTION:
      // alert("Audits Updated Successfully");
      return {
        ...state,
        success: action.payload,
        failure: false,
      }
    case SUBMIT_AUDITS_FAILURE_ACTION:
      // alert("Audits Not Updated");
      return {
        ...state,
        failure: true
      }
    case FETCH_ID_SUCCESS_ACTION:
      // alert(actions.payload.message);
      return {
        ...state,
        card: action.payload,
        cardError: false,
      }
    case FETCH_ID_FAILURE_ACTION:
      return {
        ...state,
        cardError: true
      }
    case ASSIGN_AUDITS_SUCCESS_ACTION:
      // alert(actions.payload.message);
      return {
        ...state,
        assign: action.payload,
        assignError: false,
      }
    case ASSIGN_AUDITS_FAILURE_ACTION:
      return {
        ...state,
        assignError: true
      }
    case FILTER_SUCCESS_ACTION:
      return {
        ...state,
        filters: action.payload.data,
        metrics: action.payload.counts,
        filterError: false,
      }
    case FILTER_FAILURE_ACTION:
      return {
        ...state,
        filterError: true,
      }
    case SET_EMAIL:
      return {
        ...state,
        loginId: action.payload
      }
    case ASSIGN_USERS_SUCCESS_ACTION:
      return {
        ...state,
        assignUsers: action.payload.Users,
      }
    case GET_REJECT_CASES_SUCCESS_ACTION:
      return {
        ...state,
        rejectedCases: action.payload.rejectedCases
      }
    default:
      return state
  }
  // return structuredClone(state);
}
