import React, { useEffect, useState } from "react";
import { Segment, Label, Grid, Button, Dropdown, Dimmer, Loader, Icon } from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, withRouter } from "react-router";
import "react-datepicker/dist/react-datepicker.css";
import MailPopup from "./MailPopup.jsx";
import {
  bulkReportMail,
  ConsolidatedReportdownload,
  ConsolidatedReportMail
} from "../../actions/users_action";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";

import DateFnsUtils from "@date-io/date-fns";
import { isLoggedIn } from "../../util.js";

export function Report(props) {
  const navigation = useNavigate()
  const dispatch = useDispatch()
  const auth = useSelector((rdx) => rdx.auth)
  const accessToken = auth.accessToken
  const user = useSelector((rdx) => rdx.user)
  const islogin = auth.isLoggedIn
  const [state, setStateValue] = useState({
    fromDate: null,
    toDate: null,
    firstDateChoose: null,
    bulkfirstDateChoose: null,
    isMailPopupView: false,
    isPopupView: false,
    status: "",
    mailIds: "",
    bulkfromDate: null,
    bulktoDate: null,
    states: [],
    selectTLId: '',
    selectFeId: '',
    selectTMId: '',
    selectState: '',
    downloadLoader: false
  })
  const setState = (value = {}) => {
    setStateValue((old) => {
      return { ...old, ...value }
    })
  }
  const handleFromDate = (date) => {
    console.log(date);
    setState({
      fromDate: date,
      firstDateChoose: date,
    });
  };
  const handleToDate = (date) => {
    setState({
      toDate: date,
    });
  };

  const handlebulkFromDate = (date) => {
    setState({
      bulkfromDate: date,
      bulkfirstDateChoose: date,
    });
  };
  const handlebulkToDate = (date) => {
    setState({
      bulktoDate: date,
    });
  };
  const onStatusChange = (data) => {
    setState({ status: data });
  };

  const onStateChange = (data) => {
    setState({ selectState: data })
  }

  const handleMailbulkReportClick = () => {
    setState({ isMailPopupView: !state.isMailPopupView });
  };

  const handleMailReportClick = () => {
    setState({ isPopupView: !state.isPopupView });
  };

  const handleSendMailbulkReportClick = (mailIds) => {
    setState({
      isMailPopupView: !state.isMailPopupView,
      mailId: mailIds,
    });
    var From = state.bulkfromDate;
    var To = state.bulktoDate;
    var mailId = mailIds;

    if (From < To === true) {
      let startDate = moment(From).format("DD-MM-YYYY");

      let endDate = moment(To).format("DD-MM-YYYY");

      bulkReportMail(accessToken,dispatch,startDate, endDate, mailId, {
        onSuccess: () => {
          alert("You Will Receive Reports given emailId");
        },
        onFailure: () => {
          alert("Report Not Send");
        },
      });
    } else {
      alert("choose a date greater than from date");
    }
  };

  const handleSendMailReportClick = (mailIds) => {
    setState({
      isMailPopupView: !state.isMailPopupView,
      mailId: mailIds,
    });
    var From = state.fromDate;
    var To = state.toDate;
    var status = state.status;
    var mailId = mailIds;

    ConsolidatedReportMail(accessToken,dispatch,From, To, status, mailId, {
      onSuccess: () => {
        alert("You Will Receive Reports given emailId");
      },
      onFailure: () => {
        alert("Report Not Send");
      },
    });
  };

  const downloadReport = () => {
    var From = state.fromDate;
    var To = state.toDate;
    var status = state.status;
    var teamLeaderId = state.selectTLId;
    var teamMemberId = state.selectTMId;
    var fieldExecutiveId = state.selectFeId;
    console.log(From < To, "checkkk");
    if (From < To === true) {
      let startDate = moment(From).format("DD-MM-YYYY");

      let endDate = moment(To).format("DD-MM-YYYY");
      setState({ downloadLoader: true })
      ConsolidatedReportdownload(accessToken,dispatch,startDate, endDate, status, teamLeaderId, teamMemberId, fieldExecutiveId,
        {
          onSuccess: () => {
            setState({ downloadLoader: false });
          },
          onFailure: () => {
            setState({ downloadLoader: false });
          },
        }
      );
    } else {
      alert("choose a date greater than from date");
    }
  };

  const tlOnChange = (e, Combo) => {
    let tl = []
    auth.reportTL.map((d) => {
      if (d.name === Combo.value) {
        tl.push({
          key: d.id,
          text: d.name,
          value: d.name
        })
      }

    })
    if (tl.length > 0) {
      if (state.selectTLId != tl[0].key) {
        setState({ selectTL: Combo.value, selectTLId: tl[0].key, selectTM: '', selectTMId: '', selectFE: '', selectFeId: '' })
      }
    }
    else {
      setState({ selectTL: Combo.value, selectTLId: 'All', selectTM: '', selectTMId: '', selectFE: '', selectFeId: '' })
    }
  }

  const tmOnChange = (e, Combo) => {
    let tm = []
    auth.reportTM.map((d) => {
      if (d.name === Combo.value) {
        tm.push({
          key: d.id,
          text: d.name,
          value: d.name
        })
      }

    })
    if (tm.length > 0) {
      if (state.selectTMId != tm[0].key) {
        setState({ selectTM: Combo.value, selectTMId: tm[0].key, selectFeId: '', selectFE: '' })
      }
    } else {
      setState({ selectTM: Combo.value, selectTMId: "All", selectFeId: '', selectFE: '' })
    }
  }

  const feOnChange = (e, Combo) => {
    let fe = []
    auth.reportFE.map((d) => {
      if (d.name === Combo.value) {
        fe.push({
          key: d.id,
          text: d.name,
          value: d.name
        })
      }

    })
    if (fe.length > 0) {
      setState({ selectFE: Combo.value, selectFeId: fe[0].key })
    } else {
      setState({ selectFE: Combo.value, selectFeId: "All" })
    }
  }




  var isValidTL = (state.selectTLId != "" && state.selectTLId != "All")
  var isValidTM = (state.selectTMId != "" && state.selectTMId != "All")
  let reportTL = [{ key: "All", value: "All", text: "All" }]
  if (auth.reportTL != undefined) {
    auth.reportTL.map((e) => {
      reportTL.push({ text: e.name, value: e.name, key: e.id });
    });
  }
  let reportTM = [{ key: "All", value: "All", text: "All" }]
  if (auth.reportTM != undefined) {
    auth.reportTM.map((e) => {
      if (isValidTL) {
        if (e.teamLeaderId == state.selectTLId) {
          reportTM.push({ text: e.name, value: e.name, key: e.id });
        }
      }
      else {
        reportTM.push({ text: e.name, value: e.name, key: e.id });
      }
    });
  }
  let reportFE = [{ key: "All", value: "All", text: "All" }]
  if (auth.reportFE != undefined) {
    console.log("ReportFE", auth.reportFE[0])
    auth.reportFE.map((e) => {
      if (isValidTM) {
        if (e.teamMemberId == state.selectTMId) {
          console.log("valid TM", e)
          reportFE.push({ text: e.name, value: e.name, key: e.id });
        }
      }
      else {
        if (isValidTL) {
          console.log(e)
          if (e.teamLeaderId == state.selectTLId) {
            reportFE.push({ text: e.name, value: e.name, key: e.id });
          }
        }
        else {
          console.log("invalid TL", e)
          reportFE.push({ text: e.name, value: e.name, key: e.id });
        }
      }
    });
  }
  const statusOptions = [
    { key: 1, value: "All", text: "All" },
    {
      key: 2,
      value: "Initial",
      text: "Initial",
    },
    {
      key: 3,
      value: "Assigned to FE",
      text: "Assigned to FE",
    },
    {
      key: 4,
      value: "Assigned to TL",
      text: "Assigned to TL",
    },
    {
      key: 5,
      value: "Assigned to TM",
      text: "Assigned to TM",
    },
    {
      key: 6,
      value: "Audited",
      text: "Audited",
    },
    {
      key: 7,
      value: "Completed",
      text: "Completed",
    },
  ];
  return (
    <div style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          // width: "98vw",
        }}
      >
        <Dimmer active={state.downloadLoader}>
          <Loader>Downloading Report</Loader>
        </Dimmer>
        <h1 style={{ marginTop: 30, color: "teal" }}>
          Overall Reports Status Wise
        </h1>
        <Segment raised style={{ marginLeft: "5px"}}>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={state.fromDate}
                    onChange={handleFromDate}
                    placeholder="From - Date"
                    style={{ width: "100%" }}
                  />
                </MuiPickersUtilsProvider>
              </Grid.Column>
              <Grid.Column width={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={state.toDate}
                    onChange={handleToDate}
                    placeholder="To - Date"
                    style={{ width: "100%" }}
                  />
                </MuiPickersUtilsProvider>
              </Grid.Column>
              <Grid.Column width={3}>
                <Dropdown
                  style={{ width: "100%" }}
                  selection
                  placeholder="Select Status"
                  options={statusOptions}
                  value={state.status}
                  onChange={(e, data) => {
                    onStatusChange(data.value);
                  }}
                />
              </Grid.Column>
              {auth.role === 'Admin' &&
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Grid.Column width={3}>
                    <Dropdown
                      style={{ width: "100%" }}
                      selection
                      placeholder="Select TL"
                      options={reportTL}
                      value={state.selectTL}
                      onChange={tlOnChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={3} style={{ marginLeft: 20 }}>
                    <Dropdown
                      style={{ width: "100%" }}
                      selection
                      placeholder="Select TM"
                      options={reportTM}
                      value={state.selectTM}
                      onChange={tmOnChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={3} style={{ marginLeft: 20 }}>
                    <Dropdown
                      style={{ width: "100%" }}
                      selection
                      placeholder="Select FE"
                      options={reportFE}
                      value={state.selectFE}
                      onChange={feOnChange}
                    />
                  </Grid.Column>
                </div>

              }
              {auth.role === 'Team Leader' &&
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Grid.Column width={3} style={{ marginLeft: 20 }}>
                    <Dropdown
                      style={{ width: "100%" }}
                      selection
                      placeholder="Select TM"
                      options={reportTM}
                      value={state.selectTM}
                      onChange={tmOnChange}
                    />
                  </Grid.Column>
                  <Grid.Column width={3} style={{ marginLeft: 20 }}>
                    <Dropdown
                      style={{ width: "100%" }}
                      selection
                      placeholder="Select FE"
                      options={reportFE}
                      value={state.selectFE}
                      onChange={feOnChange}
                    />
                  </Grid.Column>
                </div>

              }
              {auth.role === 'Team Member' &&
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Grid.Column width={3} style={{ marginLeft: 20 }}>
                    <Dropdown
                      style={{ width: "100%" }}
                      selection
                      placeholder="Select FE"
                      options={reportFE}
                      value={state.selectFE}
                      onChange={feOnChange}
                    />
                  </Grid.Column>
                </div>

              }

              <Grid.Column width={3}>
                <Button
                  primary
                  icon
                  labelPosition="right"
                  onClick={downloadReport}
                  // style={{ width: "70%" }}
                >
                  <Icon name="download" />
                  Download
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          justifyContent: "center",
          // width: "98vw",
        }}
      >
        <h1 style={{ marginTop: 50, color: "teal" }}>
          Bulk Downloads for Individual Report
        </h1>
        <Segment raised style={{ marginLeft: "5px" }}>
          <Grid padded>
            <Grid.Row>
              <Grid.Column width={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={state.bulkfromDate}
                    onChange={handlebulkFromDate}
                    placeholder="From - Date"
                    style={{ width: "100%" }}
                  />
                </MuiPickersUtilsProvider>
              </Grid.Column>
              <Grid.Column width={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    value={state.bulktoDate}
                    onChange={handlebulkToDate}
                    placeholder="To - Date"
                    style={{ width: "100%" }}
                  />
                </MuiPickersUtilsProvider>
              </Grid.Column>
              <Grid.Column width={4}>
                <Button
                  color="blue"
                  icon
                  labelPosition="right"
                  onClick={handleMailbulkReportClick}
                  style={{ width: "60%" }}
                >
                  <Icon name="mail outline" />
                  Mail
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>

        {state.isMailPopupView ? (
          <MailPopup
            open={state.isMailPopupView}
            mailIds={state.mailIds}
            closePopup={handleMailbulkReportClick}
            sendMailAndClosePopup={handleSendMailbulkReportClick}
          />
        ) : null}
        {state.isPopupView ? (
          <MailPopup
            open={state.isPopupView}
            mailIds={state.mailIds}
            // closePopup={this.handleMailReportClick.bind(this)}
            sendMailAndClosePopup={handleSendMailReportClick}
          />
        ) : null}
      </div>
    </div>
  );
}


// const mapStateToProps = (state) => {
//   return {
//     auth: state.auth,
//     report: state.report,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return bindActionCreators(
//     {
//       bulkReportMail: bulkReportMail,
//       ConsolidatedReportdownload: ConsolidatedReportdownload,
//       ConsolidatedReportMail: ConsolidatedReportMail
//     },
//     dispatch
//   );
// };

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Report));
export default Report;
